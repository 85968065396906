import styled from 'styled-components';
import { useQueryClient } from 'react-query';
import { getPath, isAdvertViewable } from '../../../../common/utils';
import { useLogout } from '../../../hooks/api/useLogout';
import { useGetSubscription } from '../../../hooks/api/useSubscription';
import { useGetUserData } from '../../../hooks/api/useUserData';
import { color, property, zIndex } from '../../../theme/variables';
import { size } from '../../../theme/utils';
import { responsive } from '../../../theme/responsive';
import { RouteKey } from '../../../navigation/routes';
import { useGlobalModal } from '../../../context';
import { NavLink } from '../../ui-lib/links';
import { Container } from '../layout';
import { LoginModal } from '../modals/LoginModal';
import { DesktopHeader } from './DesktopHeader';
import { MobileHeader } from './MobileHeader';
// @ts-ignore
// eslint-disable-next-line import/no-unresolved
import logoUrl from '../../../../assets/logo.svg?url';

const TEXT = {
  logoAlt: 'Miško paslaugos logotipas',
};

const StyledHeader = styled.header`
  background-color: ${color.bgWhite};
  box-shadow: ${property.boxShadow};
  padding: ${size(3)} 0;
  position: fixed;
  width: 100%;
  z-index: ${zIndex.header};
`;

const StyledContainer = styled(Container)`
  display: flex;
  justify-content: space-between;
`;

const Logo = styled.img`
  height: 49px;
  width: 97px;
`;

const DesktopContent = styled.div`
  display: none;

  @media ${responsive.tabletLandscapeUp} {
    display: flex;
  }
`;

const MobileContent = styled.div`
  display: flex;

  @media ${responsive.tabletLandscapeUp} {
    display: none;
  }
`;

const Header = () => {
  const queryClient = useQueryClient();
  const { setModal } = useGlobalModal();
  const { data: user } = useGetUserData();
  const { mutate } = useLogout();
  const { data: subscription } = useGetSubscription(user);

  const advertsViewable = isAdvertViewable(subscription);

  const logout = async () => {
    await mutate();
    await queryClient.invalidateQueries('userData');
    window.location.href = RouteKey.Index;
  };

  const openLoginModal = () => {
    setModal(<LoginModal />);
  };

  const isAuthenticated = !!user;

  return (
    <StyledHeader>
      <StyledContainer>
        <NavLink to={getPath(RouteKey.Index)}>
          <Logo src={logoUrl} alt={TEXT.logoAlt} />
        </NavLink>
        <DesktopContent>
          <DesktopHeader
            user={user}
            onLogin={openLoginModal}
            onLogout={logout}
            isAuthenticated={isAuthenticated}
            activeSubscription={advertsViewable}
          />
        </DesktopContent>
        <MobileContent>
          <MobileHeader
            user={user}
            onLogin={openLoginModal}
            onLogout={logout}
            isAuthenticated={isAuthenticated}
            activeSubscription={advertsViewable}
          />
        </MobileContent>
      </StyledContainer>
    </StyledHeader>
  );
};

export { Header };

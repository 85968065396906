import styled from 'styled-components';
import { rem } from '../../../theme/utils';
import { color, typography } from '../../../theme/variables';
import { flexCenter } from '../../../theme/mixins';

const Root = styled.span`
  align-items: center;
  color: ${color.textPrimary};
  display: flex;
  font-size: ${rem(18)};
  font-weight: ${typography.fontWeightBold};
  height: 100%;
  line-height: ${typography.headingLineHeight};
  ${flexCenter()};
  position: relative;

  &:hover {
    color: ${color.active};
  }

  &:focus {
    color: ${color.link};
  }
`;

type HeaderButtonProps = {
  children: React.ReactNode;
};

const HeaderButton = ({ children }: HeaderButtonProps) => <Root>{children}</Root>;

export { HeaderButton };

/**
 * This file holds "global" z-indexes.
 * Global z-index means that any elements should respect it (like popover should cover everything).
 * Local z-indexes (- 1, 0 or -1) used to define elements stack on that component's scope ONLY.
 * Global z-indexes starts from 10 to avoid colliding with local ones.
 */

export const zIndex = {
  dropdown: 20,
  header: 10,
  filters: 5,
};

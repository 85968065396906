import styled, { css } from 'styled-components';
import { size } from '../../../theme/utils';
import { Heading1, Heading3 } from '../typography';

type ModalTitleProps = {
  title: string;
  variant?: 'large' | 'medium' | 'small';
};

const commonStyles = css`
  text-align: center;
`;

const HeadingLarge = styled(Heading1)`
  margin-bottom: ${size(13)};
  ${commonStyles};
`;

const HeadingMedium = styled(Heading1)`
  margin-bottom: ${size(9)};
  ${commonStyles};
`;

const HeadingSmall = styled(Heading3)`
  margin-bottom: ${size(3)};
`;

const ModalTitle = ({ title, variant = 'large' }: ModalTitleProps) => {
  const Header =
    {
      small: HeadingSmall,
      medium: HeadingMedium,
      large: HeadingLarge,
    }[variant] || HeadingLarge;

  return <Header as="h2">{title}</Header>;
};

export { ModalTitle };

import { forwardRef } from 'react';
import styled from 'styled-components';
import { FieldError } from 'react-hook-form';
import { ClearedInput } from '../../../theme/resets';
import { color, property } from '../../../theme/variables';
import { placeholder } from '../../../theme/typography-styles';
import { rem, size } from '../../../theme/utils';
import { Label, ErrorText } from '../typography';

type InputProps = React.InputHTMLAttributes<HTMLInputElement>;

type TextInputProps = Omit<InputProps, 'type'> & {
  id: string;
  label: React.ReactNode;
  error?: FieldError;
  type?: 'text' | 'email' | 'password' | 'tel' | 'number' | 'hidden';
  noMargin?: boolean;
  tailingIcon?: React.ReactNode;
};

const Root = styled.div<{ noMargin: boolean }>`
  margin-bottom: ${(props) => !props.noMargin && size(7)};
  position: relative;
`;

const Input = styled(ClearedInput)<{ iconShown: boolean }>`
  border: 1px solid ${color.border};
  border-radius: ${property.borderRadius}px;
  display: block;
  font-size: ${rem(14)};
  padding: ${size(2.5)} ${size(4)};
  padding-right: ${(props) => props.iconShown && size(14)};
  width: 100%;

  &:focus {
    border-color: ${color.activeFocus};
  }

  &::placeholder {
    ${placeholder};
    opacity: 1;
  }
`;

const TextInput = forwardRef<HTMLInputElement, TextInputProps>(
  ({ id, label, type = 'text', error, noMargin = false, tailingIcon, ...other }, ref) => (
    <Root noMargin={noMargin}>
      <Label htmlFor={id}>{label}</Label>
      <Input
        type={type}
        name={id}
        id={id}
        ref={ref}
        iconShown={Boolean(tailingIcon)}
        aria-labelledby={id}
        {...other}
      />
      {tailingIcon}
      {error && <ErrorText>{error.message}</ErrorText>}
    </Root>
  ),
);

export { TextInput };

import { flatten } from 'lodash';
import { Advert, AdvertDirection, Subscription, SubscriptionStatus, User } from '../types';
import { ETHNOGRAPHIC_REGIONS } from '../constants/ethnographicRegions';

const getAdvertLocationTitle = (advert: Advert) => {
  if (advert.ethnographicRegions?.length) {
    const ethnographicRegions = advert.ethnographicRegions?.map((ethnographicRegion) =>
      ETHNOGRAPHIC_REGIONS.find((d) => d.value === ethnographicRegion),
    );

    return flatten(ethnographicRegions)
      .map((ethnographicRegion) => ethnographicRegion?.label)
      .join(', ');
  }

  if (advert.pin) {
    return `${advert.pin.latitude}, ${advert.pin.longitude}`;
  }

  return '-------';
};

// returns [latitude, longitude]
const extractCoordinates = (coordinates: string) =>
  coordinates.split(',').map((number) => parseFloat(number));

const isAdvertViewable = (subscription: Subscription | undefined) =>
  subscription?.status === SubscriptionStatus.Active;

const getDirectionFromQueryParams = (direction: 'search' | 'provide') => {
  const DIRECTION_MAP = {
    search: AdvertDirection.Search,
    provide: AdvertDirection.Provide,
  };

  return DIRECTION_MAP[direction];
};

const isOwner = (advert: Advert, user?: User) => user && advert.advertiser.userId === user.id;

export {
  getAdvertLocationTitle,
  getDirectionFromQueryParams,
  extractCoordinates,
  isAdvertViewable,
  isOwner,
};

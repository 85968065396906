import styled from 'styled-components';
import { ClearedButton } from '../../../theme/resets';
import { link } from '../../../theme/typography-styles';
import { ButtonProps } from './Button';

type LinkButtonProps = ButtonProps & {
  children: React.ReactNode;
};

const Root = styled(ClearedButton)`
  ${link};
`;

const LinkButton = ({ children, ...props }: LinkButtonProps) => <Root {...props}>{children}</Root>;

export { LinkButton };

import yup from './validation';
import { AdvertDirection, AdvertServiceType, SelectOption, UserType } from './types';
import { QUARRY_PRODUCTION_OPTIONS, TYPES_OF_SERVICE } from './constants/advert';

const PHONE_REGEX = /(\+3706)[0-9]{7}$/;
const TWO_DECIMAL_DIGITS_REGEX = /^\d+(\.\d{1,2})?$/;

const TEXT = {
  required: 'Privaloma įvesti',
  requiredNumber: 'Privaloma įvesti skaičių',
  phoneFormat: 'Telefono formatas turi būti +3706XXXXXXX',
  minOneSymbol: 'Privalo būti bent vienas simbolis',
  passwordMatch: 'Slaptažodžiai turi sutapti',
  chooseCategory: 'Pasirinkite kategoriją',
  chooseLocation: 'Pasirinkite vietą',
  twoDigits: 'Privalo būti skaičius su dviem skaitmenimis po kablelio arba mažiau',
  coordinatesFormat: 'Privalo būti skaičius, kurio skyriklis yra taškas (.)',
};

const loginSchema = yup.object().shape({
  email: yup.string().email().required(),
  password: yup.string().required(),
});

const signUpSchema = yup.object().shape({
  email: yup.string().email().required(),
  phone: yup.string().matches(PHONE_REGEX, TEXT.phoneFormat).required(),
});

const passwordResetSchema = yup.object().shape({
  email: yup.string().email().required(),
});

const serviceTypeSubscriptionSchema = yup.object().shape({
  serviceTypes: yup.mixed<SelectOption[]>().test({
    test: (value: SelectOption[] | undefined) => (value?.length ? Boolean(value?.length) : true),
    message: TEXT.required,
  }),
});

const newPasswordSetSchema = yup.object().shape({
  newPassword: yup
    .string()
    .required()
    .min(6)
    .test('isValidPass', TEXT.minOneSymbol, (value = '') => /[0-9]/.test(value)),
  newPasswordConfirm: yup
    .string()
    .oneOf([yup.ref('newPassword')], TEXT.passwordMatch)
    .required(),
});

const userSettingsEditExtendedSchema = yup.object().shape({
  email: yup.string().email().required(),
  phone: yup.string().matches(PHONE_REGEX, TEXT.phoneFormat).required(),
  type: yup.string().oneOf(Object.values(UserType)).required(),
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  companyName: yup.string().when('type', {
    is: (type: UserType) => type === UserType.Company,
    then: yup.string().required(),
    otherwise: yup.string(),
  }),
  companyCode: yup.string().when('type', {
    is: (type: UserType) => type === UserType.Company,
    then: yup.string().required(),
    otherwise: yup.string(),
  }),
  companyAddress: yup.string().when('type', {
    is: (type: UserType) => type === UserType.Company,
    then: yup.string().required(),
    otherwise: yup.string(),
  }),
  companyVat: yup.string().when('type', {
    is: (type: UserType) => type === UserType.Company,
    then: yup.string().required(),
    otherwise: yup.string(),
  }),
  file: yup.string(),
});

const changePasswordSchema = yup.object().shape({
  currentPassword: yup.string().required(),
  newPassword: yup
    .string()
    .required()
    .min(6)
    .test('isValidPass', TEXT.minOneSymbol, (value = '') => /[0-9]/.test(value)),
  newPasswordConfirm: yup
    .string()
    .oneOf([yup.ref('newPassword')], TEXT.passwordMatch)
    .required(),
});

const addAdvertInitialSchema = yup.object().shape({
  direction: yup.string().oneOf(Object.values(AdvertDirection)).required(),
});

const addProvideAdvertFinalSchema = yup.object().shape({
  serviceType: yup.string().oneOf(Object.values(AdvertServiceType)).required(TEXT.chooseCategory),
  fileIds: yup.array().of(yup.string()),
  description: yup.string().required(),
  ethnographicRegions: yup
    .mixed<SelectOption[]>()
    .test({
      test: (value: SelectOption[] | undefined) => Boolean(value?.length),
      message: TEXT.required,
    })
    .required(),
});

const addSearchAdvertFinalSchema = yup.object().shape({
  serviceType: yup.string().oneOf(Object.values(AdvertServiceType)).required(TEXT.chooseCategory),
  areaHectares: yup.number().when('serviceType', {
    is: (serviceType: AdvertServiceType) =>
      ![
        AdvertServiceType.WoodTrade,
        AdvertServiceType.Biofuels,
        AdvertServiceType.ForestRoads,
        AdvertServiceType.Transport,
      ].includes(serviceType),
    then: yup
      .number()
      .positive()
      .required()
      .test('maxDigitsAfterDecimal', TEXT.twoDigits, (number: number | undefined) =>
        number ? TWO_DECIMAL_DIGITS_REGEX.test(number.toString()) : false,
      )
      .typeError(TEXT.requiredNumber),
  }),
  longitude: yup.number().typeError(TEXT.coordinatesFormat).min(-180).max(180).required(),
  latitude: yup.number().typeError(TEXT.coordinatesFormat).min(-90).max(90).required(),
  cadastralNumberLocation: yup.string().when('serviceType', {
    is: (serviceType: AdvertServiceType) =>
      ![AdvertServiceType.ForestRoads, AdvertServiceType.Transport].includes(serviceType),
    then: yup.string().required(),
  }),
  cadastralNumberBlock: yup.string().when('serviceType', {
    is: (serviceType: AdvertServiceType) =>
      ![AdvertServiceType.ForestRoads, AdvertServiceType.Transport].includes(serviceType),
    then: yup.string().required(),
  }),
  cadastralNumberPlot: yup.string().when('serviceType', {
    is: (serviceType: AdvertServiceType) =>
      ![AdvertServiceType.ForestRoads, AdvertServiceType.Transport].includes(serviceType),
    then: yup.string().required(),
  }),
  description: yup.string().required(),
  seedlingSpecies: yup.mixed<SelectOption[]>().when('serviceType', {
    is: (serviceType: AdvertServiceType) => serviceType === AdvertServiceType.ForestPlanting,
    then: yup
      .mixed<SelectOption[]>()
      .test({
        test: (value: SelectOption[] | undefined) => Boolean(value?.length),
        message: TEXT.required,
      })
      .required(),
  }),
  deforestationTypes: yup.mixed<SelectOption[]>().when('serviceType', {
    is: (serviceType: AdvertServiceType) =>
      [AdvertServiceType.Deforestation, AdvertServiceType.VirginForestTrade].includes(serviceType),
    then: yup
      .mixed<SelectOption[]>()
      .test({
        test: (value: SelectOption[] | undefined) => Boolean(value?.length),
        message: TEXT.required,
      })
      .required(),
  }),
  felledSpecies: yup.mixed<SelectOption[]>().when('serviceType', {
    is: (serviceType: AdvertServiceType) =>
      [AdvertServiceType.Deforestation, AdvertServiceType.VirginForestTrade].includes(serviceType),
    then: yup
      .mixed<SelectOption[]>()
      .test({
        test: (value: SelectOption[] | undefined) => Boolean(value?.length),
        message: TEXT.required,
      })
      .required(),
    otherwise: yup.string(),
  }),
  deforestationServiceType: yup.mixed<SelectOption>().when('serviceType', {
    is: (serviceType: AdvertServiceType) => serviceType === AdvertServiceType.Deforestation,
    then: yup
      .mixed<SelectOption>()
      .test({
        test: (value: SelectOption | undefined) =>
          Boolean(value && TYPES_OF_SERVICE.find((option) => option.value === value.value)),
        message: TEXT.required,
      })
      .required(),
  }),
  volume: yup.number().when('serviceType', {
    is: (serviceType: AdvertServiceType) =>
      [AdvertServiceType.WoodTrade, AdvertServiceType.Biofuels].includes(serviceType),
    then: yup.number().min(1).typeError(TEXT.requiredNumber).required(),
    otherwise: yup.number(),
  }),
  liquidVolume: yup.number().when('serviceType', {
    is: (serviceType: AdvertServiceType) =>
      [AdvertServiceType.Deforestation, AdvertServiceType.VirginForestTrade].includes(serviceType),
    then: yup.number().min(1).typeError(TEXT.requiredNumber).required(),
    otherwise: yup.number(),
  }),
  timberSpecies: yup.mixed<SelectOption[]>().when('serviceType', {
    is: (serviceType: AdvertServiceType) => serviceType === AdvertServiceType.WoodTrade,
    then: yup
      .mixed<SelectOption[]>()
      .test({
        test: (value: SelectOption[] | undefined) => Boolean(value?.length),
        message: TEXT.required,
      })
      .required(),
    otherwise: yup.string(),
  }),
  roundTimberAssortments: yup.mixed<SelectOption[]>().when('serviceType', {
    is: (serviceType: AdvertServiceType) => serviceType === AdvertServiceType.WoodTrade,
    then: yup
      .mixed<SelectOption[]>()
      .test({
        test: (value: SelectOption[] | undefined) => Boolean(value?.length),
        message: TEXT.required,
      })
      .required(),
    otherwise: yup.string(),
  }),
  distanceMeters: yup.number().when('serviceType', {
    is: (serviceType: AdvertServiceType) => serviceType === AdvertServiceType.ForestRoads,
    then: yup.number().min(1).typeError(TEXT.requiredNumber).required(),
    otherwise: yup.number(),
  }),
  volumeSquareMeters: yup.number().when('serviceType', {
    is: (serviceType: AdvertServiceType) => serviceType === AdvertServiceType.Transport,
    then: yup.number().min(1).typeError(TEXT.requiredNumber).required(),
    otherwise: yup.number(),
  }),
  transportServiceType: yup.mixed<SelectOption[]>().when('serviceType', {
    is: (serviceType: AdvertServiceType) => serviceType === AdvertServiceType.Transport,
    then: yup
      .mixed<SelectOption[]>()
      .test({
        test: (value: SelectOption[] | undefined) => Boolean(value?.length),
        message: TEXT.required,
      })
      .required(),
  }),
  forestRoadsServiceType: yup.mixed<SelectOption[]>().when('serviceType', {
    is: (serviceType: AdvertServiceType) => serviceType === AdvertServiceType.ForestRoads,
    then: yup
      .mixed<SelectOption[]>()
      .test({
        test: (value: SelectOption[] | undefined) => Boolean(value?.length),
        message: TEXT.required,
      })
      .required(),
  }),
  quarryProduction: yup.mixed<SelectOption>().when('serviceType', {
    is: (serviceType: AdvertServiceType) => serviceType === AdvertServiceType.ForestRoads,
    then: yup
      .mixed<SelectOption>()
      .test({
        test: (value: SelectOption | undefined) =>
          Boolean(
            value && QUARRY_PRODUCTION_OPTIONS.find((option) => option.value === value.value),
          ),
        message: TEXT.required,
      })
      .required(),
  }),
  fileIds: yup.array().of(yup.string()),
});

const subscriptionSchema = yup.object().shape({
  expiresAt: yup.date().required(),
  serviceTypes: yup
    .array<AdvertServiceType[]>()
    .of(yup.mixed<AdvertServiceType>().oneOf(Object.values(AdvertServiceType)).defined())
    .required(),
});

export {
  loginSchema,
  passwordResetSchema,
  signUpSchema,
  changePasswordSchema,
  addAdvertInitialSchema,
  addSearchAdvertFinalSchema,
  addProvideAdvertFinalSchema,
  userSettingsEditExtendedSchema,
  newPasswordSetSchema,
  subscriptionSchema,
  serviceTypeSubscriptionSchema,
};

import { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { rgba } from 'polished';
import { AdvertDirection, User } from '../../../../common/types';
import { getPath, getUserName } from '../../../../common/utils';
import { ClearedButton } from '../../../theme/resets';
import { color, property, typography } from '../../../theme/variables';
import { size } from '../../../theme/utils';
import { RouteKey } from '../../../navigation/routes';
import { Icon } from '../../ui-lib/icons';
import { Heading3, Heading5 } from '../../ui-lib/typography';
import { Button } from '../../ui-lib/buttons';
import { NavLink } from '../../ui-lib/links';
import { AddAdvertButton } from '../buttons';

type MobileHeaderProps = {
  isAuthenticated: boolean;
  activeSubscription: boolean;
  user?: User;
  onLogin: () => void;
  onLogout: () => void;
};

const TEXT = {
  searchingAdverts: 'Ieško paslaugos',
  providingAdverts: 'Teikia paslaugą',
  userAdverts: 'Mano skelbimai',
  userSettings: 'Nustatymai',
  logout: 'Atsijungti',
  login: 'Prisijungti',
  advertSearch: 'Skelbimų paieška',
  partnersCatalogue: 'Portalu naudojasi',
};

const Drawer = styled.div`
  background: ${color.bgWhite};
  box-shadow: ${property.boxShadowInset};
  height: calc(100vh - 70px);
  padding: ${size(9)} 0;
  position: absolute;
  right: 0;
  top: 73px; /* header height */
  width: ${size(75)};
`;

const MenuAction = styled(NavLink)<{ $showActiveBorder?: boolean }>`
  align-items: center;
  border-bottom: 1px solid ${color.border};
  color: ${color.textPrimary};
  display: flex;
  font-weight: ${typography.fontWeightBold};
  ${({ $showActiveBorder }) =>
    $showActiveBorder &&
    css`
      > span {
        color: ${color.link};
      }
    `}
  padding: ${size(4)} ${size(4)} ${size(4)} ${size(9)};
  width: 100%;
`;

const MenuText = styled(Heading3)`
  margin-left: ${size(3)};
`;

const Nav = styled.nav`
  display: flex;
  flex-direction: column;
`;

const Backdrop = styled.div`
  background: ${rgba(color.linkHover, 0.84)} none repeat scroll 0 0;
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 73px;
  z-index: -1;
`;

const Chevron = styled(Icon)`
  margin-left: auto;
  transform: rotate(270deg);
`;

const Title = styled(Heading5)`
  border-bottom: 1px solid ${color.border};
  margin: ${size(15)} 0 0;
  padding: 0 ${size(9)} ${size(2)};
`;

const ButtonWrapper = styled.div`
  margin-top: ${size(15)};
  padding-left: ${size(4)};
  padding-right: ${size(4)};
`;

const AddButton = styled(AddAdvertButton)`
  justify-content: center;
  margin-bottom: ${size(2)};
  width: 100%;
`;

const ActionButton = styled(Button)`
  justify-content: center;
`;

const IconButton = styled(ClearedButton)`
  &:hover {
    color: ${color.textPrimary};
  }
`;

const MobileHeader = ({
  isAuthenticated,
  activeSubscription,
  user,
  onLogin,
  onLogout,
}: MobileHeaderProps) => {
  const [opened, setOpened] = useState(false);

  const closeMenu = () => setOpened(false);

  useEffect(() => {
    window.document.addEventListener('keydown', (e) => {
      const keyCode = e.code;

      if (keyCode === 'Escape') {
        closeMenu();
      }
    });
  }, []);

  useEffect(() => {
    const body = window.document.querySelector('body');

    if (!body) {
      return;
    }

    if (opened) {
      body.style.overflow = 'hidden';
    } else {
      body.style.overflow = 'auto';
    }
  }, [opened]);

  return (
    <>
      <IconButton onClick={() => setOpened(!opened)}>
        {opened ? <Icon name="close" size={24} /> : <Icon name="hamburger" size={48} />}
      </IconButton>
      {opened && (
        <>
          <Drawer>
            {isAuthenticated ? (
              <>
                <Nav>
                  <MenuAction to={getPath(RouteKey.UserSettings)}>
                    <Icon name="user" />
                    <MenuText as="span">{user && getUserName(user)}</MenuText>
                    <Chevron name="chevron-down" />
                  </MenuAction>
                  <MenuAction
                    to={getPath(RouteKey.UserAdverts)}
                    $showActiveBorder={[RouteKey.UserSavedAdverts, RouteKey.UserAdverts].includes(
                      window.location.pathname as RouteKey,
                    )}
                  >
                    <Heading3 as="span">{TEXT.userAdverts}</Heading3>
                    <Chevron name="chevron-down" />
                  </MenuAction>

                  {activeSubscription && (
                    <>
                      <Title as="p" secondary>
                        {TEXT.advertSearch}
                      </Title>
                      <MenuAction
                        to={getPath(RouteKey.Adverts, {
                          direction: AdvertDirection.Search.toLowerCase(),
                        })}
                      >
                        <Heading3 as="span">{TEXT.searchingAdverts}</Heading3>
                        <Chevron name="chevron-down" />
                      </MenuAction>
                      <MenuAction
                        to={getPath(RouteKey.Adverts, {
                          direction: AdvertDirection.Provide.toLowerCase(),
                        })}
                      >
                        <Heading3 as="span">{TEXT.providingAdverts}</Heading3>
                        <Chevron name="chevron-down" />
                      </MenuAction>
                    </>
                  )}
                </Nav>
                <ButtonWrapper>
                  <AddButton />
                  <ActionButton fullWidth onClick={onLogout} variant="secondary">
                    {TEXT.logout}
                  </ActionButton>
                </ButtonWrapper>
              </>
            ) : (
              <Nav>
                <MenuAction
                  to={getPath(RouteKey.PartnersCatalogue)}
                  $showActiveBorder={window.location.pathname === RouteKey.PartnersCatalogue}
                >
                  <Heading3 as="span">{TEXT.partnersCatalogue}</Heading3>
                  <Chevron name="chevron-down" />
                </MenuAction>
                <ButtonWrapper>
                  <ActionButton fullWidth onClick={onLogin}>
                    {TEXT.login}
                  </ActionButton>
                </ButtonWrapper>
              </Nav>
            )}
          </Drawer>
          <Backdrop onClick={closeMenu} />
        </>
      )}
    </>
  );
};

export { MobileHeader };

const TRANSITION_DELAY = 450;
const TRANSITION_TIMING_ARGS = [0.23, 1, 0.32, 1];
const TRANSITION_EASING = `cubic-bezier(${TRANSITION_TIMING_ARGS.join()})`;

/**
 * This file should contain computed properties (like a value for box-shadow, transition, etc.)
 */
export const property = {
  borderRadius: 4,
  borderRadiusLg: 18,
  transition: `${TRANSITION_DELAY}ms ${TRANSITION_EASING} 0ms`,
  boxShadow: `0 0 14px rgba(0, 0, 0, 0.16)`,
  boxShadowInset: `inset 0 12px 14px -12px rgba(0, 0, 0, 0.16)`,
  boxShadowSmall: `0 0.8px 1.6px rgba(0, 0, 0, 0.1)`,
  filtersHeightDesktop: 243,
  filtersHeightMobile: 96,
  headerHeight: 73,
};

/**
 * This file should contain everything related to fonts and typography.
 */

export const typography = {
  fontSize: 16,
  fontFamily: '"Lato", sans-serif',
  fontWeightBold: 700,
  fontWeightRegular: 400,
  defaultLineHeight: 1.5,
  headingLineHeight: 1.25,
};

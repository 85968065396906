import React from 'react';
import { color } from '../../app/theme/variables';
import {
  AdvertDirection,
  AdvertServiceType,
  DeforestationServiceType,
  DeforestationType,
  ForestRoadsServiceType,
  QuarryProduction,
  RoundTimberAssortment,
  SelectOption,
  TransportServiceType,
  TreeSpecies,
} from '../types';
import PlowingIcon from '../../assets/advert-illustrations/plowing.svg';
import ForestPlantingIcon from '../../assets/advert-illustrations/forestPlanting.svg';
import DeforestationIcon from '../../assets/advert-illustrations/deforestation.svg';
import RepellentLubricationIcon from '../../assets/advert-illustrations/repellentLubrication.svg';
import SelfGreeneryMowingIcon from '../../assets/advert-illustrations/selfGreeneryMowing.svg';
import SaplingGrowingIcon from '../../assets/advert-illustrations/saplingGrowing.svg';
import ForestManagementProjectsIcon from '../../assets/advert-illustrations/forestManagementProjects.svg';
import WoodLotDissectionIcon from '../../assets/advert-illustrations/woodLotDissection.svg';
import WoodTradeIcon from '../../assets/advert-illustrations/woodTrade.svg';
import VirginForestTradeIcon from '../../assets/advert-illustrations/virginForestTrade.svg';
import ForestWithLandTradeIcon from '../../assets/advert-illustrations/forestWithLandTrade.svg';
import BiofuelsIcon from '../../assets/advert-illustrations/biofuels.svg';
import ForestRoadsIcon from '../../assets/advert-illustrations/forestRoads.svg';
import TransportIcon from '../../assets/advert-illustrations/transport.svg';

const ADVERT_COLOR_HEX_VALUE: Record<AdvertServiceType, string> = {
  PLOWING: color.cardBrown,
  DEFORESTATION: color.cardBrownDark,
  BIOFUELS: color.cardGreen,
  VIRGIN_FOREST_TRADE: color.cardGreenLight,
  FOREST_PLANTING: color.cardGreenDark,
  REPELLENT_LUBRICATION: color.cardPink,
  FOREST_WITH_LAND_TRADE: color.cardPurple,
  SELF_GREENERY_MOWING: color.cardRed,
  FOREST_MANAGEMENT_PROJECTS: color.cardBlue,
  WOOD_TRADE: color.cardBlueLight,
  WOOD_LOT_DISSECTION: color.cardYellow,
  SAPLING_GROWING: color.cardSalad,
  FOREST_ROADS: color.cardOrange,
  TRANSPORT: color.cardBlueDark,
};

const ADVERT_ICON: Record<AdvertServiceType, React.FC> = {
  PLOWING: PlowingIcon,
  FOREST_PLANTING: ForestPlantingIcon,
  DEFORESTATION: DeforestationIcon,
  REPELLENT_LUBRICATION: RepellentLubricationIcon,
  SELF_GREENERY_MOWING: SelfGreeneryMowingIcon,
  SAPLING_GROWING: SaplingGrowingIcon,
  FOREST_MANAGEMENT_PROJECTS: ForestManagementProjectsIcon,
  WOOD_LOT_DISSECTION: WoodLotDissectionIcon,
  WOOD_TRADE: WoodTradeIcon,
  VIRGIN_FOREST_TRADE: VirginForestTradeIcon,
  FOREST_WITH_LAND_TRADE: ForestWithLandTradeIcon,
  BIOFUELS: BiofuelsIcon,
  FOREST_ROADS: ForestRoadsIcon,
  TRANSPORT: TransportIcon,
};

const ADVERT_TYPE_LABEL: Record<AdvertDirection, string> = {
  PROVIDE: 'Teikiamos paslaugas',
  SEARCH: 'Ieškomos paslaugos',
};

const ADVERT_LABEL: Record<AdvertServiceType, string> = {
  PLOWING: 'Dirvos arimas',
  FOREST_PLANTING: 'Miško sodinimas',
  DEFORESTATION: 'Miško kirtimas',
  REPELLENT_LUBRICATION: 'Repelentų tepimas',
  SELF_GREENERY_MOWING: 'Savaiminių želdinių pjovimas',
  SAPLING_GROWING: 'Jaunuolynų ugdymas',
  FOREST_MANAGEMENT_PROJECTS: 'Miškotvarkos projektų rengimas',
  WOOD_LOT_DISSECTION: 'Biržių atrėžimas',
  WOOD_TRADE: 'Medienos pirkimas / pardavimas',
  VIRGIN_FOREST_TRADE: 'Pirkimas / pardavimas stačiu mišku',
  FOREST_WITH_LAND_TRADE: 'Miško su žeme pirkimas / pardavimas',
  BIOFUELS: 'Biokuras',
  FOREST_ROADS: 'Miško keliai',
  TRANSPORT: 'Transportas',
};

const ADVERT_SERVICE_TYPE_OPTIONS: SelectOption[] = Object.values(AdvertServiceType).map(
  (value) => ({
    value,
    label: ADVERT_LABEL[value],
  }),
);

const ADVERT_DIRECTION_OPTIONS: SelectOption[] = Object.values(AdvertDirection).map((value) => ({
  value,
  label: ADVERT_TYPE_LABEL[value],
}));

const ADVERT_FORM_LABELS: Record<string, React.ReactNode> = {
  area: 'Plotas, ha',
  cadastralNumber: 'Kadastrinis numeris',
  cadastralNumberLocation: 'Vietovė',
  cadastralNumberBlock: 'Blokas',
  cadastralNumberPlot: 'Sklypas',
  description: 'Aprašymas',
  seedlingSpecies: 'Sodmenų rūšys',
  deforestationTypes: 'Miško kirtimo tipai',
  deforestationServiceType: 'Paslaugos tipas',
  felledSpecies: 'Kertami medžiai',
  placeholder: 'Pasirinkite',
  timberSpecies: 'Medžio rūšis',
  phone: 'Telefono numeris',
  email: 'El. paštas',
  latitude: 'Platumos koordinatė',
  longitude: 'Ilgumos koordinatė',
  region: 'Regionas',
  roundTimberAssortments: 'Sortimentas',
  distance: 'Atstumas, m',
  forestRoadsServiceType: 'Paslaugos tipas',
  quarryProduction: 'Karjerinė produkcija',
  transportServiceType: 'Paslaugos tipas',
  volumeSquareMeters: (
    <span>
      Tūris pervežimui, m<sup>3</sup>
    </span>
  ),
  volume: (
    <span>
      Tūris, m<sup>3</sup>
    </span>
  ),
  liquidVolume: (
    <span>
      Likvidinis tūris, m<sup>3</sup>
    </span>
  ),
};

const TREE_SPECIES_LABEL: Record<TreeSpecies, string> = {
  OAK: 'Ąžuolas',
  WHITE_ALDER: 'Baltalksnis',
  BIRCH: 'Beržas',
  GOAT_WILLOW: 'Blindė',
  ASPEN: 'Drebulė',
  SPRUCE: 'Eglė',
  WILLOW: 'Gluosnis',
  ELM: 'Guoba',
  BLACK_ALDER: 'Juodalksnis',
  MAPLE: 'Klevas',
  LINDEN: 'Liepa',
  LARCH: 'Maumedis',
  PINE: 'Pušis',
  HORNBEAM: 'Skroblas',
  COTTONWOOD: 'Tuopa',
  ASH: 'Uosis',
  OTHER_HARDWOODS: 'Kiti kietieji lapuočiai',
  OTHER_SOFTWOODS: 'kiti minkštieji lapuočiai',
  OTHER_CONIFERS: 'Kiti spygliuočiai',
  OTHER: 'Kita',
};

const SEEDLING_SPECIES: SelectOption[] = [
  {
    value: TreeSpecies.Pine,
    label: TREE_SPECIES_LABEL.PINE,
  },
  {
    value: TreeSpecies.Spruce,
    label: TREE_SPECIES_LABEL.SPRUCE,
  },
  {
    value: TreeSpecies.Birch,
    label: TREE_SPECIES_LABEL.BIRCH,
  },
  {
    value: TreeSpecies.Other,
    label: TREE_SPECIES_LABEL.OTHER,
  },
];

const DEFORESTATION_TYPE_LABEL: Record<DeforestationType, string> = {
  SELECTIVE_LOGGING: 'Atrankinis kirtimas',
  SELECTIVE_SANITARY_LOGGING: 'Atrankinis sanitarinis kirtimas',
  RANDOM_LOGGING: 'Atvėjinis kirtimas',
  LAST_INSTANCE_OF_RANDOM_LOGGING: 'Atvėjinių kirtimų paskutinis atvėjis',
  BIODIVERSITY_MAINTENANCE_LOGGING: 'Biologinės įvairovės palaikymo kirtimas',
  CURRENT_FALL: 'Einamasis kritimas',
  LANDSCAPING_LOGGING: 'Kraštovaizdžio formavimo kirtimas',
  CLEAR_LOGGING: 'Plynas kirtimas',
  CLEAR_SANITARY_LOGGING: 'Plynas sanitarinis kirtimas',
  RECONSTRUCTIVE_LOGGING: 'Rekonstrukcinis kirtimas',
  THINNING: 'Retinimas',
};

const DEFORESTATION_TYPE: SelectOption[] = [
  { value: DeforestationType.SelectiveLogging, label: DEFORESTATION_TYPE_LABEL.SELECTIVE_LOGGING },
  {
    value: DeforestationType.SelectiveSanitaryLogging,
    label: DEFORESTATION_TYPE_LABEL.SELECTIVE_SANITARY_LOGGING,
  },
  { value: DeforestationType.RandomLogging, label: DEFORESTATION_TYPE_LABEL.RANDOM_LOGGING },
  {
    value: DeforestationType.LastInstanceOfRandomLogging,
    label: DEFORESTATION_TYPE_LABEL.LAST_INSTANCE_OF_RANDOM_LOGGING,
  },
  {
    value: DeforestationType.BiodiversityMaintenanceLogging,
    label: DEFORESTATION_TYPE_LABEL.BIODIVERSITY_MAINTENANCE_LOGGING,
  },
  { value: DeforestationType.CurrentFall, label: DEFORESTATION_TYPE_LABEL.CURRENT_FALL },
  {
    value: DeforestationType.LandscapingLogging,
    label: DEFORESTATION_TYPE_LABEL.LANDSCAPING_LOGGING,
  },
  { value: DeforestationType.ClearLogging, label: DEFORESTATION_TYPE_LABEL.CLEAR_LOGGING },
  {
    value: DeforestationType.ClearSanitaryLogging,
    label: DEFORESTATION_TYPE_LABEL.CLEAR_SANITARY_LOGGING,
  },
  {
    value: DeforestationType.ReconstructiveLogging,
    label: DEFORESTATION_TYPE_LABEL.RECONSTRUCTIVE_LOGGING,
  },
  { value: DeforestationType.Thinning, label: DEFORESTATION_TYPE_LABEL.THINNING },
];

const FELLED_SPECIES: SelectOption[] = [
  { value: TreeSpecies.Oak, label: TREE_SPECIES_LABEL.OAK },
  { value: TreeSpecies.WhiteAlder, label: TREE_SPECIES_LABEL.WHITE_ALDER },
  { value: TreeSpecies.Birch, label: TREE_SPECIES_LABEL.BIRCH },
  { value: TreeSpecies.GoatWillow, label: TREE_SPECIES_LABEL.GOAT_WILLOW },
  { value: TreeSpecies.Aspen, label: TREE_SPECIES_LABEL.ASPEN },
  { value: TreeSpecies.Spruce, label: TREE_SPECIES_LABEL.SPRUCE },
  { value: TreeSpecies.Willow, label: TREE_SPECIES_LABEL.WILLOW },
  { value: TreeSpecies.Elm, label: TREE_SPECIES_LABEL.ELM },
  { value: TreeSpecies.BlackAlder, label: TREE_SPECIES_LABEL.BLACK_ALDER },
  { value: TreeSpecies.Maple, label: TREE_SPECIES_LABEL.MAPLE },
  { value: TreeSpecies.Linden, label: TREE_SPECIES_LABEL.LINDEN },
  { value: TreeSpecies.Larch, label: TREE_SPECIES_LABEL.LARCH },
  { value: TreeSpecies.Pine, label: TREE_SPECIES_LABEL.PINE },
  { value: TreeSpecies.Hornbeam, label: TREE_SPECIES_LABEL.HORNBEAM },
  { value: TreeSpecies.CottonWood, label: TREE_SPECIES_LABEL.COTTONWOOD },
  { value: TreeSpecies.Ash, label: TREE_SPECIES_LABEL.ASH },
  { value: TreeSpecies.OtherHardwoods, label: TREE_SPECIES_LABEL.OTHER_HARDWOODS },
  { value: TreeSpecies.OtherSoftwoods, label: TREE_SPECIES_LABEL.OTHER_SOFTWOODS },
  { value: TreeSpecies.OtherConifers, label: TREE_SPECIES_LABEL.OTHER_CONIFERS },
];

const TYPES_OF_SERVICE_LABEL: Record<DeforestationServiceType, string> = {
  LOGGING_ONLY: 'Kirtimas',
  LOGGING_AND_PULLING: 'Kirtimas + Traukimas',
  PULLING_ONLY: 'Traukimas',
};

const TYPES_OF_SERVICE: SelectOption[] = [
  { value: DeforestationServiceType.LoggingOnly, label: TYPES_OF_SERVICE_LABEL.LOGGING_ONLY },
  {
    value: DeforestationServiceType.LoggingAndPulling,
    label: TYPES_OF_SERVICE_LABEL.LOGGING_AND_PULLING,
  },
  { value: DeforestationServiceType.PullingOnly, label: TYPES_OF_SERVICE_LABEL.PULLING_ONLY },
];

const ROUND_TIMBER_ASSORTMENT_LABEL: Record<RoundTimberAssortment, string> = {
  SAW_LOG: 'Pjautinieji rąstai',
  PAPER_LOG: 'Popiermedis',
  LUMBER: 'Tarrąsčiai',
  PLANK_TIMBER: 'Plokščių mediena',
  OTHER: 'Kita',
};

const ROUND_TIMBER_ASSORTMENTS: SelectOption[] = [
  { value: RoundTimberAssortment.SawLog, label: ROUND_TIMBER_ASSORTMENT_LABEL.SAW_LOG },
  { value: RoundTimberAssortment.PaperLog, label: ROUND_TIMBER_ASSORTMENT_LABEL.PAPER_LOG },
  { value: RoundTimberAssortment.Lumber, label: ROUND_TIMBER_ASSORTMENT_LABEL.LUMBER },
  { value: RoundTimberAssortment.PlankTimber, label: ROUND_TIMBER_ASSORTMENT_LABEL.PLANK_TIMBER },
  { value: RoundTimberAssortment.Other, label: ROUND_TIMBER_ASSORTMENT_LABEL.OTHER },
];

const FOREST_ROADS_SERVICE_TYPE_LABEL: Record<ForestRoadsServiceType, string> = {
  QUARRY_PRODUCTION: 'Karjerinė produkcija',
  ROAD_LEVELLING: 'Kelio lyginimas',
};

const FOREST_ROADS_SERVICE_TYPE_OPTIONS: SelectOption[] = [
  {
    value: ForestRoadsServiceType.QuarryProduction,
    label: FOREST_ROADS_SERVICE_TYPE_LABEL.QUARRY_PRODUCTION,
  },
  {
    value: ForestRoadsServiceType.RoadLevelling,
    label: FOREST_ROADS_SERVICE_TYPE_LABEL.ROAD_LEVELLING,
  },
];

const TRANSPORT_SERVICE_TYPE_LABEL: Record<TransportServiceType, string> = {
  TIMBER_TRANSPORT_TRUCK: 'Miškovežis',
  CHIP_TRUCK: 'Skiedrovežis',
  TRAILER: 'Tralas',
};

const TRANSPORT_SERVICE_TYPE_OPTIONS: SelectOption[] = [
  {
    value: TransportServiceType.TimberTransportTruck,
    label: TRANSPORT_SERVICE_TYPE_LABEL.TIMBER_TRANSPORT_TRUCK,
  },
  {
    value: TransportServiceType.ChipTruck,
    label: TRANSPORT_SERVICE_TYPE_LABEL.CHIP_TRUCK,
  },
  {
    value: TransportServiceType.Trailer,
    label: TRANSPORT_SERVICE_TYPE_LABEL.TRAILER,
  },
];

const QUARRY_PRODUCTION_LABEL: Record<QuarryProduction, string> = {
  MIXTURE_OF_SAND_AND_GRAVEL_FR_0_32: 'Smėlio ir žvyro mišinys FR 0/32',
  QUARRY_GRAVEL: 'Karjerinis žvyras',
  SAND: 'Smėlis',
  DOLOMITE_AND_PEBBLE_CHIPS: 'Dolomito ir Žvirgždo skaldos',
};

const QUARRY_PRODUCTION_OPTIONS: SelectOption[] = [
  {
    value: QuarryProduction.SandAndGravelMixture,
    label: QUARRY_PRODUCTION_LABEL.MIXTURE_OF_SAND_AND_GRAVEL_FR_0_32,
  },
  { value: QuarryProduction.QuarryGravel, label: QUARRY_PRODUCTION_LABEL.QUARRY_GRAVEL },
  { value: QuarryProduction.Sand, label: QUARRY_PRODUCTION_LABEL.SAND },
  {
    value: QuarryProduction.DolomiteAndPebbleChips,
    label: QUARRY_PRODUCTION_LABEL.DOLOMITE_AND_PEBBLE_CHIPS,
  },
];

export {
  ADVERT_COLOR_HEX_VALUE,
  ADVERT_SERVICE_TYPE_OPTIONS,
  ADVERT_TYPE_LABEL,
  ADVERT_ICON,
  ADVERT_LABEL,
  ADVERT_FORM_LABELS,
  ADVERT_DIRECTION_OPTIONS,
  SEEDLING_SPECIES,
  TYPES_OF_SERVICE,
  DEFORESTATION_TYPE,
  DEFORESTATION_TYPE_LABEL,
  TREE_SPECIES_LABEL,
  FELLED_SPECIES,
  TYPES_OF_SERVICE_LABEL,
  ROUND_TIMBER_ASSORTMENTS,
  ROUND_TIMBER_ASSORTMENT_LABEL,
  FOREST_ROADS_SERVICE_TYPE_LABEL,
  FOREST_ROADS_SERVICE_TYPE_OPTIONS,
  QUARRY_PRODUCTION_OPTIONS,
  QUARRY_PRODUCTION_LABEL,
  TRANSPORT_SERVICE_TYPE_OPTIONS,
  TRANSPORT_SERVICE_TYPE_LABEL,
};

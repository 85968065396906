import styled from 'styled-components';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useGetUserData } from '../../../hooks/api/useUserData';
import { rem, size as sizeUtil } from '../../../theme/utils';
import { responsive } from '../../../theme/responsive';
import { useGlobalModal } from '../../../context';
import { Icon, IconMapMember } from '../../ui-lib/icons';
import { Heading1, Heading4, Paragraph } from '../../ui-lib/typography';
import { Button } from '../../ui-lib/buttons';
import { SignUpModal } from '../modals/SignUpModal';
import { RulesConsentModal } from '../modals/RulesConsentModal';
import { LoginModal } from '../modals/LoginModal';
import { Container } from '../layout';
import { Page } from '../page';
import bgUrl from '../../../../assets/bg.png';
import bgMobileUrl from '../../../../assets/bg-mobile.png';

const TEXT = {
  title: 'Visos miškininkystės paslaugos - vienoje vietoje.',
  subtitle:
    'Miškų paslaugos - tai vieninga platforma, skirta rasti ir teikti aukščiausios kokybės miškų priežiūros paslaugas.',
  cta: 'Registruotis',
  reason1: 'Tik patikimi skelbimai',
  reason2: 'Patogi skelbimų paieška',
  reason3: 'Lanksčios narystės sąlygos',
};

const REASONS: Array<{ title: string; iconName: IconMapMember }> = [
  {
    title: TEXT.reason1,
    iconName: 'secure-shield',
  },
  {
    title: TEXT.reason2,
    iconName: 'search',
  },
  {
    title: TEXT.reason3,
    iconName: 'verified-account',
  },
];

const Heading = styled(Heading1)`
  font-size: ${rem(43)};
  margin-bottom: ${sizeUtil(14)};

  @media ${responsive.tabletPortraitUp} {
    font-size: ${rem(50)};
  }
`;

const Wrapper = styled.div`
  margin: auto 0;
  max-width: ${sizeUtil(145)};

  @media ${responsive.tabletLandscapeUp} {
    max-width: ${sizeUtil(150)};
  }
`;

const Subtitle = styled(Paragraph)`
  margin-bottom: ${sizeUtil(7)};
`;

const List = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${sizeUtil(16)};
  margin-top: ${sizeUtil(20)};
  text-align: center;

  @media ${responsive.tabletPortraitUp} {
    flex-direction: row;
    margin-top: auto;
  }
`;

const StyledContainer = styled(Container)`
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
  padding-bottom: ${sizeUtil(20)};
  padding-top: ${sizeUtil(40)};
`;

const StyledPage = styled(Page)`
  background-image: url(${bgMobileUrl});
  background-position-x: 100%;
  background-size: ${sizeUtil(50)};
  margin-top: 0;

  @media ${responsive.tabletPortraitUp} {
    background-size: auto;
  }

  @media ${responsive.tabletLandscapeUp} {
    background-image: url(${bgUrl});
    background-size: contain;
  }
`;

const ReasonTitle = styled(Heading4)`
  margin-top: ${sizeUtil(5)};
`;

const ButtonText = styled.span`
  margin-left: ${sizeUtil(4)};
`;

const SignupButton = styled(Button)`
  width: 100%;

  @media ${responsive.tabletPortraitUp} {
    width: auto;
  }
`;

const HomePage = () => {
  const [searchParams] = useSearchParams();
  const { data: user, isFetched } = useGetUserData();
  const { setModal } = useGlobalModal();

  const continueParam = searchParams.get('continue');

  useEffect(() => {
    if (isFetched && !user && continueParam) {
      setModal(<LoginModal />);
    }
  }, [isFetched, user, continueParam, setModal]);

  useEffect(() => {
    if (isFetched && user && (!user.privacyPolicyAccepted || !user.termsOfUseAccepted)) {
      setModal(<RulesConsentModal />, 'narrow', false);
    }
  }, [isFetched, user, setModal]);

  const isAuthenticated = !!user;

  const openSignUpModal = () => {
    setModal(<SignUpModal />);
  };

  return (
    <StyledPage>
      <StyledContainer>
        <Wrapper>
          <Heading>{TEXT.title}</Heading>
          <Subtitle>{TEXT.subtitle}</Subtitle>
          {!isAuthenticated && (
            <SignupButton responsive onClick={openSignUpModal}>
              <Icon name="user-check" size={17} />
              <ButtonText>{TEXT.cta}</ButtonText>
            </SignupButton>
          )}
        </Wrapper>
        <List>
          {REASONS.map(({ title, iconName }) => (
            <div key={title}>
              <Icon name={iconName} size={50} />
              <ReasonTitle as="h2">{title}</ReasonTitle>
            </div>
          ))}
        </List>
      </StyledContainer>
    </StyledPage>
  );
};

export { HomePage };

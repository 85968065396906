import { fromUnixTime, getUnixTime } from 'date-fns';

const MILLISECONDS_FACTOR = 1000;

const formatTimeFromUnixMilliseconds = (time: number) => fromUnixTime(time / MILLISECONDS_FACTOR);

const formatDate = (date: number): string =>
  new Date(formatTimeFromUnixMilliseconds(date)).toISOString().split('T')[0];

const formatDateAndTime = (value: number): string => {
  const date = new Date(formatTimeFromUnixMilliseconds(value));
  const datePart = date.toISOString().split('T')[0];
  const timePart = date.toISOString().split('T')[1].slice(0, 5);

  return `${datePart} ${timePart}`;
};

const getUnixMilliseconds = (time: number | Date) => getUnixTime(time) * MILLISECONDS_FACTOR;

export { formatDate, formatDateAndTime, getUnixMilliseconds, formatTimeFromUnixMilliseconds };

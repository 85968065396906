import { ErrorMessage } from '../types';

const ERROR_MESSAGE_LABEL: Record<ErrorMessage, string> = {
  internal_server_error: 'Serverio klaida',
  malformed_url: 'The request was rejected because the URL was not normalized',
  path_not_found: 'Nerasta',
  invalid_request: 'Neteisinga užklausa',
  missing_parameters: 'Trūkta užklausos parametrų',
  access_denied: 'Prieiga nesuteikta',
  entity_not_found: 'Subjektas nerastas',

  // Custom
  bad_credentials: 'Blogi prisijungimo duomenys',
  user_already_exists: 'Vartotojas jau egzistuoja',
  password_reset_token_invalid: 'Neteisingas slaptažodžio atkūrimo žetonas',
  too_many_password_reset_requests: 'Per daug kartų bandyta pakeisti slaptažodį',
  incorrect_new_password_confirmation: 'Neteisingas naujo slaptažodžio patvirtinimas',
  incorrect_password: 'Neteisingas dabartinis slaptažodis',
  new_password_cant_be_same_as_current_password: 'Naujas slaptažodis negali sutapti su dabartiniu',
  functionality_inaccessible: 'Funkcionalumas nepasiekiamas',
  invalid_subscription: 'Negalima prenumerata',
  file_access_denied: 'Failas nepasiekiamas',
  unsupported_file_type: 'Negalimas failo formatas',
};

export { ERROR_MESSAGE_LABEL };

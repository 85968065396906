import { useMutation, useQuery } from 'react-query';
import { Advert } from '../../../common/types';
import { advertApiClient, userApiClient } from '../../services/apiClient';

const saveAdvert = (id: string) => advertApiClient.put(`/${id}/save`);

const forgetAdvert = (id: string) => advertApiClient.delete(`/${id}/save`);

const getAllSavedAdverts = async (): Promise<Advert[]> => {
  const {
    data: { content },
  } = await userApiClient.get('/adverts/save', {
    params: { size: 1000 },
  });

  return content as Advert[];
};

const useSaveAdvert = (id: string) => useMutation(() => saveAdvert(id));

const useForgetAdvert = (id: string) => useMutation(() => forgetAdvert(id));

const useGetAllSavedAdverts = () =>
  useQuery<Advert[]>(['savedAdverts'], getAllSavedAdverts, { cacheTime: 0, retry: false });

export { useSaveAdvert, useForgetAdvert, useGetAllSavedAdverts };

import styled, { css } from 'styled-components';
import { flexCenter } from '../../../theme/mixins';
import { color } from '../../../theme/variables';
import { size } from '../../../theme/utils';
import { Header } from './Header';
import { Footer } from './Footer';
// @ts-ignore
// eslint-disable-next-line import/no-unresolved
import treesBgUrl from '../../../../assets/trees-bg.svg?url';

type MainProps = {
  background?: 'white' | 'grey';
  verticalCentered?: boolean;
  horizontalCentered?: boolean;
  textCentered?: boolean;
  treesShown?: boolean;
  padded?: boolean;
  className?: string;
};

type PageProps = {
  children: React.ReactNode;
  footerVisible?: boolean;
} & MainProps;

const getBackgroundColor = (bg: 'grey' | 'white') =>
  ({ grey: color.lightBgGrey, white: color.bgWhite }[bg]);

const treesBg = css`
  background-image: url(${treesBgUrl});
  background-position-x: 100%;
  background-size: contain;
`;

const Main = styled.main<MainProps>`
  background-color: ${({ background = 'white' }) => getBackgroundColor(background)};
  flex: 1;
  flex-direction: column;
  margin-top: 73px; /* header height */
  ${(props) => flexCenter(props.verticalCentered, props.horizontalCentered)};
  ${(props) => props.treesShown && treesBg}
  padding: ${(props) => props.padded && `${size(13)} 0 ${size(18)}`};
  text-align: ${(props) => props.textCentered && `center`};
`;

const Page = ({
  children,
  background = 'white',
  verticalCentered = false,
  horizontalCentered = false,
  textCentered = false,
  treesShown = false,
  padded = false,
  footerVisible = true,
  className,
}: PageProps) => (
  <>
    <Header />
    <Main
      background={background}
      verticalCentered={verticalCentered}
      horizontalCentered={horizontalCentered}
      textCentered={textCentered}
      treesShown={treesShown}
      padded={padded}
      className={className}
    >
      {children}
    </Main>
    {footerVisible && <Footer />}
  </>
);

export { Page };

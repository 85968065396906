import ReactSelect, { OptionProps, Props as ReactSelectProps } from 'react-select';
import styled from 'styled-components';
import React, { CSSProperties } from 'react';
import { FieldError } from 'react-hook-form';
import { SelectOption } from '../../../../common/types';
import { color, typography } from '../../../theme/variables';
import { rem, size } from '../../../theme/utils';
import { ErrorText, Label } from '../typography';

type SelectProps = Omit<
  ReactSelectProps,
  'id' | 'label' | 'options' | 'placeholder' | 'onChange' | 'defaultValue'
> & {
  id: string;
  label: React.ReactNode;
  placeholder: React.ReactNode;
  options: SelectOption[];
  onChange?: (option: SelectOption | null) => void;
  defaultValue?: SelectOption | null;
  error?: FieldError;
  noMargin?: boolean;
};

const customStyles = {
  indicatorSeparator: () => ({
    display: 'none',
  }),
  control: (provided: CSSProperties, { isDisabled }: { isDisabled: boolean }) => ({
    ...provided,
    borderColor: color.border,
    fontSize: rem(14),
    backgroundColor: isDisabled ? color.lightBgGrey : color.bgWhite,
    ...(isDisabled && {
      pointerEvents: 'auto',
      cursor: 'not-allowed',
    }),
  }),
  menu: (provided: CSSProperties) => ({
    ...provided,
    margin: 0,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
  }),
  input: (provided: CSSProperties) => ({
    ...provided,
    padding: '10px 0',
    margin: 0,
  }),
  valueContainer: (provided: CSSProperties) => ({
    ...provided,
    padding: '0 16px',
    fontSize: 13,
  }),
  option: (provided: CSSProperties, state: OptionProps<{}>) => {
    let background = color.bgWhite;
    let fontWeight = typography.fontWeightRegular;
    let cursor = 'default';

    if (state.isSelected || state.isFocused || state.isDisabled) {
      background = color.lightBgGrey;
    }

    if (state.isSelected) {
      fontWeight = typography.fontWeightBold;
    }

    if (state.isDisabled) {
      cursor = 'not-allowed';
    }

    return { ...provided, background, color: color.textPrimary, fontWeight, cursor };
  },
};

const Root = styled.div<{ noMargin: boolean }>`
  margin-bottom: ${(props) => !props.noMargin && size(7)};
  width: 100%;
`;

const Select = ({
  id,
  options,
  placeholder,
  defaultValue,
  label,
  error,
  onChange,
  noMargin = false,
  ...props
}: SelectProps) => (
  <Root noMargin={noMargin}>
    <Label>{label}</Label>
    <ReactSelect
      {...props}
      inputId={id}
      options={options}
      placeholder={placeholder}
      isClearable
      defaultValue={defaultValue}
      // @ts-ignore
      styles={customStyles}
      // @ts-ignore
      onChange={onChange}
      theme={(theme) => ({
        ...theme,
        colors: {
          ...theme.colors,
          primary: color.border,
          primary25: color.lightBgGrey,
        },
      })}
    />
    {error && <ErrorText>{error.message}</ErrorText>}
  </Root>
);

export { Select };
export type { SelectOption, SelectProps };

import { forwardRef } from 'react';
import { FieldError } from 'react-hook-form';
import styled from 'styled-components';
import { rem, size } from '../../../theme/utils';
import { ClearedTextArea } from '../../../theme/resets';
import { color, property } from '../../../theme/variables';
import { ErrorText, Label } from '../typography';

type TextAreaProps = {
  id: string;
  label: React.ReactNode;
  error?: FieldError;
  rows?: number;
};

const DEFAULT_ROWS = 9;

const Root = styled.div`
  margin-bottom: ${size(7)};
  position: relative;
`;

const TextAreaElement = styled(ClearedTextArea)`
  border: 1px solid ${color.border};
  border-radius: ${property.borderRadius}px;
  display: block;
  font-size: ${rem(14)};
  padding: ${size(2.5)} ${size(4)};
  width: 100%;
`;

const TextArea = forwardRef<HTMLTextAreaElement, TextAreaProps>(
  ({ id, label, error, rows = DEFAULT_ROWS, ...other }, ref) => (
    <Root>
      <Label htmlFor={id}>{label}</Label>
      <TextAreaElement id={id} ref={ref} aria-labelledby={id} rows={rows} {...other} />
      {error && <ErrorText>{error.message}</ErrorText>}
    </Root>
  ),
);

export { TextArea };

import styled from 'styled-components';
import { size } from '../../../theme/utils';
import { Alert, AlertProps } from '../alerts';

type FormAlertProps = Pick<AlertProps, 'title' | 'children'> & {
  variant?: 'large' | 'small';
};

const getSpaceBelow = (variant: 'large' | 'small') => {
  if (variant === 'large') {
    return `${size(-7)} 0 ${size(7)}`;
  }

  return ` ${size(5)} 0 ${size(7)}`;
};

const Root = styled(Alert)<AlertProps & { variant: 'large' | 'small' }>`
  margin: ${(props) => getSpaceBelow(props.variant)};
`;

const FormAlert = ({ title, variant = 'large', children }: FormAlertProps) => (
  <Root severity="error" variant={variant} title={title} closable={false}>
    {children}
  </Root>
);

export { FormAlert };

import { useMutation } from 'react-query';
import { publicApiClient } from '../../services/apiClient';

interface PasswordResetFormModel {
  email: string;
}

const resetPassword = (data: PasswordResetFormModel) =>
  publicApiClient.put('/password-resets', data);

export const useResetPassword = () => useMutation(resetPassword);
export type { PasswordResetFormModel };

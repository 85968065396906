import styled from 'styled-components';
import { size } from '../../../theme/utils';
import { Heading1 } from '../../ui-lib/typography';

type PageHeadingProps = {
  title: string;
};

const Root = styled(Heading1)`
  margin-bottom: ${size(7)};
`;

const PageHeading = ({ title }: PageHeadingProps) => <Root>{title}</Root>;

export { PageHeading };

const PALETTE = {
  black: '#000',
  white: '#fff',
  green900: '#273522',
  green700: '#6B8650',
  green500: '#8DAC4A',
  green400: '#B2C784',
  green200: '#CCE597',
  green100: '#FCFFF5',
  grey700: '#899797',
  grey500: '#D0D6D6',
  grey200: '#F5F6F6',
  blue700: '#0E566C',
  blue500: '#9CC9E8',
  blue200: '#F8FFFF',
  brown700: '#7A4D12',
  brown500: '#CCBEA0',
  brown200: '#FFFAF3',
  red700: '#973937',
  red500: '#E0ACAB',
  red200: '#FFF6F6',
};

export const color = {
  // Colors (semantic, depends on raw brand colors).
  link: PALETTE.green700,
  linkHover: PALETTE.green900,
  textPrimary: PALETTE.green900,
  textSecondary: PALETTE.grey700,
  textDisabled: PALETTE.grey700,
  placeholder: PALETTE.grey500,
  border: PALETTE.grey500,
  borderDark: PALETTE.grey700,
  borderGreen: PALETTE.green400,
  borderRed: PALETTE.red500,
  borderBlue: PALETTE.blue500,
  borderBrown: PALETTE.brown500,
  lightBgRed: PALETTE.red200,
  lightBgGreen: PALETTE.green100,
  lightBgBlue: PALETTE.blue200,
  lightBgBrown: PALETTE.brown200,
  lightBgGrey: PALETTE.grey200,
  bgWhite: PALETTE.white,
  invalid: PALETTE.red700,
  active: PALETTE.green400,
  activeHover: PALETTE.green200,
  activeFocus: PALETTE.green500,
  activeDisabled: PALETTE.grey500,

  // Custom card colors
  cardBrown: '#8B7660',
  cardBrownDark: '#360606',
  cardGreenDark: '#407134',
  cardGreenLight: '#7B864F',
  cardGreen: '#ADC180',
  cardSalad: '#AFDD59',
  cardYellow: '#E3CD52',
  cardPurple: '#B196ED',
  cardPink: '#D6A3C3',
  cardBlue: '#5ABBD8',
  cardBlueDark: '#174FBA',
  cardBlueLight: '#7CAC9F',
  cardRed: '#B74421',
  cardOrange: '#F06820',
  cardGrey: PALETTE.grey700,

  // Color palette should only be used when there's no appropriate "semantic" color.
  palette: PALETTE,
};

import { useMutation } from 'react-query';
import { userApiClient } from '../../services/apiClient';

const agreeToRules = () =>
  userApiClient.put('/agreement-acceptances', {
    privacyPolicyAccepted: true,
    termsOfUseAccepted: true,
  });

export const useAgreeToRules = () => useMutation(agreeToRules);

import { forwardRef } from 'react';
import styled from 'styled-components';
import { FieldError } from 'react-hook-form';
import { rem, size } from '../../../theme/utils';
import { VisuallyHiddenElement } from '../../../theme/resets';
import { color } from '../../../theme/variables';
import { circle, flexCenter } from '../../../theme/mixins';
import { ErrorText, Paragraph } from '../typography';

type RadioProps = {
  id: string;
  label: React.ReactNode;
  error?: FieldError;
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, 'type' | 'children'>;

const Label = styled.label`
  align-items: center;
  cursor: pointer;
  display: inline-flex;
  flex-direction: row;
`;

const Text = styled(Paragraph)`
  font-size: ${rem(14)};
  margin-left: ${size(2)};
`;

const Circle = styled.span`
  border: 1px solid ${color.border};
  ${circle(16)};
  ${flexCenter()};
  overflow: hidden;
`;

const Inner = styled.span`
  background-color: ${color.bgWhite};
  ${circle(12)};
`;

const Input = styled(VisuallyHiddenElement)`
  &:checked {
    ~ ${Circle} {
      border-color: ${color.active};

      ${Inner} {
        background-color: ${color.active};
      }
    }
  }

  &[disabled] {
    /* stylelint-disable-next-line */
    ~ ${Circle}, ~ ${Text} {
      cursor: not-allowed;
    }
  }
`;

const Radio = forwardRef<HTMLInputElement, RadioProps>(({ id, label, error, ...props }, ref) => (
  <Label>
    <Input as="input" {...props} type="radio" ref={ref} />
    <Circle>
      <Inner />
    </Circle>
    <Text as="span">{label}</Text>
    {error && <ErrorText>{error.message}</ErrorText>}
  </Label>
));

export { Radio };

import { useQuery } from 'react-query';
import { Subscription, User } from '../../../common/types';
import { userApiClient } from '../../services/apiClient';

const getSubscription = async (): Promise<Subscription> => {
  const response = await userApiClient.get('/subscription');
  const { data } = response;

  return data;
};

const useGetSubscription = (user?: User) =>
  useQuery<Subscription>('subscription', getSubscription, {
    retry: false,
    refetchOnMount: false,
    enabled: !!user,
  });

export { useGetSubscription };

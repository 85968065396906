import { Page } from '../../components/common/page';
import { Container } from '../../components/common/layout';
import { SubHeader } from '../../components/ui-lib/typography';

const TEXT = {
  title: 'Puslapis kraunamas...',
};

const LoadingScreen = () => (
  <Page verticalCentered horizontalCentered textCentered>
    <Container>
      <SubHeader>{TEXT.title}</SubHeader>
    </Container>
  </Page>
);

export { LoadingScreen };

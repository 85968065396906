import styled from 'styled-components';
import { ClearedButton } from '../../../theme/resets';
import { size } from '../../../theme/utils';
import { Icon } from '../../ui-lib/icons';

type PasswordToggleProps = {
  passwordShown: boolean;
  setPasswordShown: (value: boolean) => void;
};

const EyeButton = styled(ClearedButton)`
  position: absolute;
  right: ${size(4)};
  top: ${size(8)};
`;

const PasswordToggle = ({ passwordShown, setPasswordShown }: PasswordToggleProps) => (
  <EyeButton
    type="button"
    onClick={(e) => {
      e.preventDefault();
      setPasswordShown(!passwordShown);
    }}
  >
    <Icon name={passwordShown ? 'eye-open' : 'eye-closed'} />
  </EyeButton>
);

export { PasswordToggle };

import { useRef, useState } from 'react';
import styled from 'styled-components';
import { useClickAway, useKey } from 'react-use';
import { ClearedButton, ClearedUl } from '../../../theme/resets';
import { color, property } from '../../../theme/variables';
import { flexCenter } from '../../../theme/mixins';
import { size } from '../../../theme/utils';
import { HeaderButton } from '../../common/buttons';
import { Icon } from '../icons';

type DropdownProps = {
  button: React.ReactNode;
  items: { key: string; node: React.ReactNode }[];
  showCaret?: boolean;
};

const List = styled(ClearedUl)`
  background-color: ${color.bgWhite};
  border-radius: ${property.borderRadius}px;
  box-shadow: ${property.boxShadow};
  overflow: hidden;
  position: absolute;
  right: 0;
  top: ${size(15)};
  width: ${size(42)};
`;

const Root = styled.div`
  ${flexCenter()};
  position: relative;
`;

const Button = styled(ClearedButton)`
  height: 100%;
`;

const ListItem = styled.li`
  display: flex;

  &:hover {
    background-color: ${color.activeHover};
  }
`;

const ChevronIcon = styled(Icon)<{ $opened: boolean }>`
  transform: ${({ $opened }) => ($opened ? 'rotate(180deg)' : 'none')};
`;

const Dropdown = ({ showCaret = false, button, items }: DropdownProps) => {
  const [opened, setOpened] = useState(false);
  const ref = useRef(null);

  useClickAway(ref, () => {
    setOpened(false);
  });

  useKey('Escape', () => setOpened(false));

  return (
    <Root>
      <Button type="button" aria-expanded={opened} onClick={() => setOpened(true)}>
        <HeaderButton>
          {button}
          {showCaret && <ChevronIcon name="chevron-down" $opened={opened} />}
        </HeaderButton>
      </Button>
      {opened && (
        <List ref={ref}>
          {items.map((item) => (
            <ListItem key={item.key}>{item.node}</ListItem>
          ))}
        </List>
      )}
    </Root>
  );
};

export { Dropdown };

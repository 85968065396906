import styled from 'styled-components';
import { ClearedButton } from '../../../theme/resets';
import { color } from '../../../theme/variables';
import { size } from '../../../theme/utils';
import { Icon, IconMapMember } from '../../ui-lib/icons';

type ActionButtonProps = {
  icon: IconMapMember;
  onClick: () => void;
  text: string;
  className?: string;
  type?: 'button' | 'submit';
};

const Root = styled(ClearedButton)`
  align-items: center;
  color: ${color.palette.blue700};
  display: flex;
  gap: ${size(1)};

  &:hover {
    text-decoration: underline;
  }
`;

const ActionButton = ({ icon, onClick, text, className, type }: ActionButtonProps) => (
  <Root
    onClick={(e) => {
      e.preventDefault();
      onClick();
    }}
    className={className}
    type={type}
  >
    <Icon name={icon} size={16} />
    {text}
  </Root>
);

export { ActionButton };

import styled from 'styled-components';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { AxiosError } from 'axios';
import { signUpSchema } from '../../../../common/schemas';
import { getErrorMessage } from '../../../../common/utils';
import { SignUpUserFormModel, useSignUp } from '../../../hooks/api/useSignUp';
import { flexCenter } from '../../../theme/mixins';
import { size } from '../../../theme/utils';
import { useGlobalModal } from '../../../context';
import { ModalTitle } from '../../ui-lib/modals';
import { FormAlert } from '../../ui-lib/forms/FormAlert';
import { Form, TextInput } from '../../ui-lib/forms';
import { Button, LinkButton } from '../../ui-lib/buttons';
import { Paragraph } from '../../ui-lib/typography';
// eslint-disable-next-line import/no-cycle
import { LoginModal } from './LoginModal';

const TEXT = {
  title: 'Registracija',
  email: 'Elektroninis paštas',
  phone: 'Telefono numeris',
  description: 'Po sėkmingos aplikacijos, mūsų komanda su Jumis susisieks per 1 d.d',
  cta: 'Registruotis',
  haveAcc: 'Jau turite paskyrą?',
  login: 'Prisijunkite',
  errorTitle: 'Prisiregistruoti nepavyko',
  successText:
    'Registracijos duomenys sėkmingai pateikti. Mes artimiausiu metu su Jumis susisieksime.',
};

const ButtonRow = styled.div`
  ${flexCenter()};
`;

const LoginLink = styled(LinkButton)`
  display: inline;
  text-decoration: underline;
`;

const TextWrapper = styled(Paragraph)`
  padding: ${size(13)} ${size(20)} 0;
`;

const Description = styled(Paragraph)`
  margin: ${size(6)} 0 ${size(8)};
  padding: 0 ${size(20)};
`;

const StyledForm = styled(Form)`
  max-width: 100%;
`;

const Wrapper = styled.div`
  align-self: center;
  max-width: ${size(86)};
  width: 100%;
`;

const Text = styled(Paragraph)`
  margin: 0 auto;
  max-width: ${size(110)};
`;

const SignUpModal = () => {
  const { setModal } = useGlobalModal();
  const { mutate, isLoading, isError, isSuccess, error } = useSignUp();
  const { formState, register, handleSubmit } = useForm<SignUpUserFormModel>({
    mode: 'onSubmit',
    defaultValues: {
      email: '',
      phone: '',
    },
    resolver: yupResolver(signUpSchema),
  });

  const { errors } = formState;
  const errorMessage = getErrorMessage(error as AxiosError);

  async function onSubmit(formData: SignUpUserFormModel): Promise<void> {
    await mutate(formData);
  }

  return (
    <>
      <ModalTitle title={TEXT.title} />
      {isError && <FormAlert title={TEXT.errorTitle}>{errorMessage}</FormAlert>}
      {isSuccess ? (
        <Text>{TEXT.successText}</Text>
      ) : (
        <>
          <StyledForm onSubmit={handleSubmit(onSubmit)}>
            <Wrapper>
              <TextInput
                id="email"
                type="email"
                label={TEXT.email}
                error={errors.email}
                {...register('email', { required: true })}
              />
              <TextInput
                id="phone"
                type="tel"
                placeholder="+3706XXXXXXX"
                label={TEXT.phone}
                error={errors.phone}
                {...register('phone', { required: true })}
              />
            </Wrapper>
            <Description>{TEXT.description}</Description>
            <ButtonRow>
              <Button type="submit" disabled={isLoading}>
                {TEXT.cta}
              </Button>
            </ButtonRow>
          </StyledForm>
          <TextWrapper>
            {TEXT.haveAcc}{' '}
            <LoginLink type="button" onClick={() => setModal(<LoginModal />)}>
              {TEXT.login}
            </LoginLink>
          </TextWrapper>
        </>
      )}
    </>
  );
};

export { SignUpModal };

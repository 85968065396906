import styled from 'styled-components';
import { size } from '../../../theme/utils';

type FormProps = React.FormHTMLAttributes<HTMLFormElement>;

const Root = styled.form`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: ${size(86)};
`;

const Form = (props: FormProps) => <Root {...props} />;

export { Form };

import styled from 'styled-components';
import {
  h1,
  h2,
  h3,
  h4,
  h5,
  p,
  errorText,
  link,
  placeholder,
  subHeader,
  TypographyProps,
  label,
} from '../../../theme/typography-styles';

const Heading1 = styled.h1<TypographyProps>`
  ${h1}
`;

const Heading2 = styled.h2<TypographyProps>`
  ${h2}
`;

const Heading3 = styled.h3<TypographyProps>`
  ${h3}
`;

const Heading4 = styled.h4<TypographyProps>`
  ${h4}
`;

const Heading5 = styled.h5<TypographyProps>`
  ${h5}
`;

const Paragraph = styled.p<TypographyProps>`
  ${p}
`;

const Placeholder = styled.span`
  ${placeholder}
`;

const Link = styled.a`
  ${link}
`;

const ErrorText = styled.span`
  ${errorText}
`;

const SubHeader = styled.p`
  ${subHeader}
`;

const Label = styled.label`
  ${label}
`;

export {
  Heading1,
  Heading2,
  Heading3,
  Heading4,
  Heading5,
  Paragraph,
  Placeholder,
  Link,
  ErrorText,
  SubHeader,
  Label,
};

import { useMemo } from 'react';
import styled from 'styled-components';
import { square } from '../../../theme/mixins';
import { iconMap, IconMapMember } from './icon-map';

type SVGElementProps = React.HTMLAttributes<SVGElement>;

type IconStyleProps = {
  size?: number | { width: number; height: number };
  color?: string;
  className?: string;
};

type IconProps = SVGElementProps &
  IconStyleProps & {
    name: IconMapMember;
  };

const StyledIcon = styled.span<IconStyleProps>`
  ${({ color }: IconStyleProps) => color}
  /* stylelint-disable-next-line */
  ${({ size = 24 }: IconStyleProps) =>
    typeof size === 'number' ? square(size) : { width: size.width, height: size.height }};
`;

const Icon = ({ name, color, size, className, ...props }: React.PropsWithChildren<IconProps>) => {
  const IconComponent = useMemo(() => iconMap[name], [name]);

  return (
    <StyledIcon
      size={size}
      color={color}
      as={IconComponent}
      className={className}
      aria-hidden
      focusable={false}
      {...props}
    />
  );
};

export type { IconProps };
export { Icon };

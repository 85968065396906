import { css } from 'styled-components';

const flexCenter = (verticalCentered = true, horizontalCentered = true) => css`
  align-items: ${horizontalCentered ? 'center' : 'flex-start'};
  display: flex;
  justify-content: ${verticalCentered ? 'center' : 'flex-start'};
`;

const square = (size: number) => css`
  height: ${size}px;
  width: ${size}px;
`;

const circle = (size: number) => css`
  border-radius: 50%;
  ${square(size)};
`;

export { flexCenter, square, circle };

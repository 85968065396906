import { css } from 'styled-components';
import { color } from '../variables';

const focus = () => css`
  outline: 0;

  .focus-visible & {
    box-shadow: 0 0 0 3px ${color.palette.white}, 0 0 0 5px ${color.activeFocus};
  }
`;

export { focus };

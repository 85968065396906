import { ClearedButton } from '../../../theme/resets';

type AnchorProps = React.AnchorHTMLAttributes<HTMLAnchorElement>;

type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement>;

type LinkOrButtonProps = AnchorProps &
  ButtonProps & {
    as?: React.ElementType;
  };

/**
 * A polymorphic component that renders `<button>` element by default.
 *
 * If `href` prop is passed, then it will be rendered as  `<a />` tag.
 *
 * Component has no additional JSX or styles, it just renders HTML tag conditionally.
 */
const LinkOrButton = ({ href, children, as: RenderAs, ...props }: LinkOrButtonProps) => {
  if (RenderAs) {
    return (
      <RenderAs href={href} {...props}>
        {children}
      </RenderAs>
    );
  }

  if (href) {
    return (
      <a href={href} {...props}>
        {children}
      </a>
    );
  }

  return (
    <ClearedButton type="button" {...props}>
      {children}
    </ClearedButton>
  );
};

export { LinkOrButton };
export type { LinkOrButtonProps };

import styled, { css } from 'styled-components';
import { rem, size } from '../../../theme/utils';
import { color, property, typography } from '../../../theme/variables';
import { responsive as responsiveUtil } from '../../../theme/responsive';
import { LinkOrButton, LinkOrButtonProps } from './LinkOrButton';

type ButtonProps = {
  variant?: 'primary' | 'secondary';
  fullWidth?: boolean;
  responsive?: boolean;
} & LinkOrButtonProps;

type StylingPropTypes = Omit<ButtonProps, 'children' | 'onClick'>;

const getColorsByVariant = ({ variant }: StylingPropTypes) => {
  if (variant === 'secondary') {
    return css`
      background-color: ${color.bgWhite};
      border-color: ${color.linkHover};

      &:hover,
      &:focus {
        border-color: ${color.active};
      }

      &:focus,
      &:disabled {
        background-color: ${color.lightBgGrey};
      }

      &:disabled {
        border-color: ${color.borderDark};
        color: ${color.textDisabled};
      }
    `;
  }

  return css`
    background-color: ${color.active};

    &:hover {
      background-color: ${color.activeHover};
    }

    &:focus {
      background-color: ${color.activeFocus};
    }

    &:disabled {
      background-color: ${color.activeDisabled};
    }
  `;
};

const StyledButton = styled(LinkOrButton)<StylingPropTypes>`
  border: 1px solid transparent;
  border-radius: ${property.borderRadius}px;
  color: ${color.textPrimary};
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  display: inline-flex;
  font-size: ${rem(14)};
  font-weight: ${typography.fontWeightBold};
  line-height: ${typography.headingLineHeight};
  ${getColorsByVariant};
  ${({ responsive }) =>
    responsive &&
    css`
      justify-content: center;

      @media ${responsiveUtil.tabletLandscapeUp} {
        justify-content: flex-start;
      }
    `}
  padding: ${size(3)} ${size(12)};
  transition: background-color ${property.transition}, border-color ${property.transition};
  width: ${({ fullWidth }) => fullWidth && '100%'};
`;

const Button = ({
  variant = 'primary',
  fullWidth = false,
  children,
  responsive = false,
  ...buttonProps
}: ButtonProps) => {
  return (
    <StyledButton variant={variant} fullWidth={fullWidth} responsive={responsive} {...buttonProps}>
      {children}
    </StyledButton>
  );
};

export { Button };
export type { ButtonProps };

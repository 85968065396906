import { AdvertServiceType } from './advert';

enum SubscriptionStatus {
  Active = 'ACTIVE',
  Disabled = 'DISABLED',
  Expired = 'EXPIRED',
}

type Subscription = {
  createdAt: number;
  expiresAt: number;
  status: SubscriptionStatus;
  subscriber: {
    userId: string;
    email: string;
    phone: string;
  };
  serviceTypes: AdvertServiceType[];
};

type SubscriptionFormValues = {
  expiresAt: Date;
  serviceTypes: AdvertServiceType[];
};

type SubscriptionPayloadValues = {
  expiresAt: number;
  serviceTypes: AdvertServiceType[];
};

export { SubscriptionStatus };
export type { Subscription, SubscriptionFormValues, SubscriptionPayloadValues };

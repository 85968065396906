enum ErrorMessage {
  InternalServerError = 'internal_server_error',
  MalformedUrl = 'malformed_url',
  PathNotFound = 'path_not_found',
  InvalidRequest = 'invalid_request',
  MissingParameter = 'missing_parameters',
  Forbidden = 'access_denied',
  EntityNotFound = 'entity_not_found',

  // Custom
  BadCredentials = 'bad_credentials',
  UserAlreadyExists = 'user_already_exists',
  PasswordResetTokenInvalid = 'password_reset_token_invalid',
  TooManyPasswordResetRequests = 'too_many_password_reset_requests',
  IncorrectNewPasswordConfirmation = 'incorrect_new_password_confirmation',
  IncorrectPassword = 'incorrect_password',
  NewPasswordCantBeSameAsCurrentPassword = 'new_password_cant_be_same_as_current_password',
  FunctionalityInaccessible = 'functionality_inaccessible',
  InvalidSubscription = 'invalid_subscription',
  FileAccessDenied = 'file_access_denied',
  UnsupportedFileType = 'unsupported_file_type',
}

export { ErrorMessage };

import styled, { css } from 'styled-components';
import { getPath, getUserName } from '../../../../common/utils';
import { AdvertDirection, User } from '../../../../common/types';
import { RouteKey } from '../../../navigation/routes';
import { ClearedButton } from '../../../theme/resets';
import { color } from '../../../theme/variables';
import { NavLink } from '../../ui-lib/links';
import { size } from '../../../theme/utils';
import { Dropdown } from '../../ui-lib/dropdown';
import { Heading4 } from '../../ui-lib/typography';
import { Icon } from '../../ui-lib/icons';
import { AddAdvertButton, HeaderButton } from '../buttons';
import { responsive } from '../../../theme/responsive';

type DesktopHeaderProps = {
  isAuthenticated: boolean;
  activeSubscription: boolean;
  user?: User;
  onLogin: () => void;
  onLogout: () => void;
};

const TEXT = {
  login: 'Prisijungti',
  userAdverts: 'Mano skelbimai',
  userSettings: 'Nustatymai',
  logout: 'Atsijungti',
  advertSearch: 'Skelbimų paieška',
  searchingAdverts: 'Ieško paslaugos',
  providingAdverts: 'Teikia paslaugą',
  partnersCatalogue: 'Portalu naudojasi',
};

const MenuLink = styled(NavLink)<{ $showActiveBorder?: boolean }>`
  ${({ $showActiveBorder }) =>
    $showActiveBorder &&
    css`
      &::after {
        content: '';
        position: absolute;
        bottom: ${size(-1)};
        height: 2px;
        width: ${size(7)};
        background-color: ${color.link};
        left: 50%;
        transform: translateX(-50%);
      }
      > span {
        color: ${color.link};
      }
    `}
  position: relative;
`;

const MenuAction = styled.span`
  padding: ${size(4)} ${size(7)};
  width: 100%;
`;

const MenuText = styled.span`
  margin-left: ${size(3)};
`;

const NavWrapper = styled.div`
  display: flex;
  gap: ${size(10)};

  @media ${responsive.desktopUp} {
    gap: ${size(19)};
  }
`;

const AddButton = styled(AddAdvertButton)`
  padding-left: ${size(6)};
  padding-right: ${size(6)};
`;

const DesktopHeader = ({
  isAuthenticated,
  activeSubscription,
  user,
  onLogin,
  onLogout,
}: DesktopHeaderProps) => (
  <>
    {isAuthenticated ? (
      <NavWrapper>
        <NavWrapper as="nav">
          {activeSubscription && (
            <Dropdown
              showCaret
              button={TEXT.advertSearch}
              items={[
                {
                  key: getPath(RouteKey.Adverts, {
                    direction: AdvertDirection.Search.toLowerCase(),
                  }),
                  node: (
                    <MenuAction
                      as={NavLink}
                      to={getPath(RouteKey.Adverts, {
                        direction: AdvertDirection.Search.toLowerCase(),
                      })}
                    >
                      <Heading4 as="span">{TEXT.searchingAdverts}</Heading4>
                    </MenuAction>
                  ),
                },
                {
                  key: getPath(RouteKey.Adverts, {
                    direction: AdvertDirection.Provide.toLowerCase(),
                  }),
                  node: (
                    <MenuAction
                      as={NavLink}
                      to={getPath(RouteKey.Adverts, {
                        direction: AdvertDirection.Provide.toLowerCase(),
                      })}
                    >
                      <Heading4 as="span">{TEXT.providingAdverts}</Heading4>
                    </MenuAction>
                  ),
                },
              ]}
            />
          )}
          <MenuLink
            to={getPath(RouteKey.UserAdverts)}
            $showActiveBorder={[RouteKey.UserSavedAdverts, RouteKey.UserAdverts].includes(
              window.location.pathname as RouteKey,
            )}
          >
            <HeaderButton>{TEXT.userAdverts}</HeaderButton>
          </MenuLink>
          <Dropdown
            button={
              <>
                <Icon name="user" />
                <MenuText>{user && getUserName(user)}</MenuText>
              </>
            }
            items={[
              {
                key: getPath(RouteKey.UserSettings),
                node: (
                  <MenuAction as={NavLink} to={getPath(RouteKey.UserSettings)}>
                    <Heading4 as="span">{TEXT.userSettings}</Heading4>
                  </MenuAction>
                ),
              },
              {
                key: 'logout',
                node: (
                  <MenuAction as={ClearedButton} onClick={onLogout}>
                    <Heading4 as="span">{TEXT.logout}</Heading4>
                  </MenuAction>
                ),
              },
            ]}
          />
        </NavWrapper>
        <AddButton />
      </NavWrapper>
    ) : (
      <NavWrapper as="nav">
        <MenuLink
          to={getPath(RouteKey.PartnersCatalogue)}
          $showActiveBorder={window.location.pathname === RouteKey.PartnersCatalogue}
        >
          <HeaderButton>{TEXT.partnersCatalogue}</HeaderButton>
        </MenuLink>
        <ClearedButton onClick={onLogin}>
          <HeaderButton>
            <Icon name="user" />
            <MenuText>{TEXT.login}</MenuText>
          </HeaderButton>
        </ClearedButton>
      </NavWrapper>
    )}
  </>
);

export { DesktopHeader };

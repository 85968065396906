import { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import styled from 'styled-components';
import { FieldError } from 'react-hook-form';
import { FileType } from '../../../../common/types';
import { color, property } from '../../../theme/variables';
import { size } from '../../../theme/utils';
import { flexCenter, square } from '../../../theme/mixins';
import { useAddFile } from '../../../hooks/api/useFiles';
import { Icon } from '../icons';
import { ErrorText, Heading3, Paragraph, SubHeader } from '../typography';
import { Button } from '../buttons';

type DropzoneProps = {
  files: FileType[];
  error?: FieldError;
  onAddedFiles: (file: FileType[], acceptedFiles?: File[]) => void;
  supportedFiles?: ('.png' | '.jpeg' | '.jpg' | '.webp' | '.pdf')[];
};

const MAX_FILES = 5;
const TOO_MANY_FILES_ERROR_CODE = 'too-many-files';

const TEXT = {
  title: 'Tempkite failą į pažymėtą zoną arba spauskite mygtuką:',
  subtitle: (files: DropzoneProps['supportedFiles']) =>
    `${files?.join(' ').trim()}. Max 10MB, Max ${MAX_FILES} failai`,
  cta: 'Pasirinkti',
  pdf: 'PDF',
  failedFile: (file: string) => `Nepavyko įkelti: ${file}`,
  reason: 'Priežastis',
  tooManyFiles: `Pasirinkote per daug failų. Galimas kiekis ${MAX_FILES}`,
};

const Root = styled.div<{ active: boolean }>`
  background-color: ${color.lightBgGrey};
  border: 1px solid ${(props) => (props.active ? color.active : color.border)};
  border-radius: ${property.borderRadius}px;
  padding: ${size(8)};
`;

const Content = styled.div`
  flex-direction: column;
  ${flexCenter()};
`;

const Text = styled(Heading3)`
  margin: ${size(5)} 0;
  text-align: center;
`;

const Subtitle = styled(SubHeader)`
  display: block;
`;

const Preview = styled.div`
  border: 1px solid ${color.border};
  border-radius: ${property.borderRadius}px;
  display: flex;
  justify-content: center;
  ${square(120)};
  overflow: hidden;
  padding: ${size(1)};
`;

const PreviewContainer = styled.div`
  display: flex;
  gap: ${size(2)};
  margin-top: ${size(2)};
`;

const PdfText = styled(SubHeader)`
  margin: auto;
`;

const Dropzone = ({
  files,
  error,
  onAddedFiles,
  supportedFiles = ['.png', '.jpeg', '.jpg', '.webp', '.pdf'],
}: DropzoneProps) => {
  const { mutateAsync } = useAddFile();
  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      const values = await Promise.all(
        acceptedFiles.map(async (file) => {
          const formData = new FormData();

          formData.append('file', file);

          const data = await mutateAsync(formData);

          return data;
        }),
      );

      const addedFiles = values.map(({ data }) => data);
      onAddedFiles(addedFiles, acceptedFiles);
    },
    [onAddedFiles, mutateAsync],
  );

  const isMaxReached = files.length === MAX_FILES;

  const { getRootProps, getInputProps, isDragActive, open, fileRejections } = useDropzone({
    onDrop,
    noClick: true,
    disabled: isMaxReached,
    noKeyboard: true,
    maxFiles: MAX_FILES - files.length,
    accept: supportedFiles,
    maxSize: 10000000, // 10mb
  });

  return (
    <>
      <Root {...getRootProps()} active={isDragActive}>
        <input {...getInputProps()} />

        <Content>
          <Icon name="upload" size={66} />
          <Text as="p">
            {TEXT.title}
            <Subtitle as="span">{TEXT.subtitle(supportedFiles)}</Subtitle>
          </Text>
          <Button disabled={isMaxReached} onClick={open}>
            {TEXT.cta}
          </Button>
        </Content>
      </Root>
      {fileRejections.map(({ file, errors: fileErrors }) => (
        <Paragraph key={file.name}>
          <ErrorText>
            {TEXT.failedFile(file.name)}. {TEXT.reason}:{' '}
          </ErrorText>
          {fileErrors.map(({ message, code }) => (
            <ErrorText key={`${file.name}-${code}`}>
              {code === TOO_MANY_FILES_ERROR_CODE ? TEXT.tooManyFiles : message}
            </ErrorText>
          ))}
        </Paragraph>
      ))}
      {error && <ErrorText>{error.message}</ErrorText>}
      <PreviewContainer>
        {files.map(({ id: fileId, type }) => (
          <Preview key={fileId}>
            {type === 'pdf' ? (
              <PdfText>{TEXT.pdf}</PdfText>
            ) : (
              <img src={`/files/${fileId}/load`} alt="" />
            )}
          </Preview>
        ))}
      </PreviewContainer>
    </>
  );
};

export { Dropzone };

import { forwardRef } from 'react';
import styled from 'styled-components';
import { VisuallyHiddenElement } from '../../../theme/resets';
import { color, property } from '../../../theme/variables';
import { flexCenter } from '../../../theme/mixins';
import { Icon } from '../icons';

type SelectorCardProps = {
  label: React.ReactNode;
  icon: React.ReactNode;
  subText?: React.ReactNode;
  className?: string;
  iconSize?: number;
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, 'type' | 'children'>;

const Body = styled.span`
  background-color: ${color.bgWhite};
  border: 2px solid ${color.border};
  border-radius: ${property.borderRadius}px;
  cursor: pointer;
  flex-direction: column;
  height: 100%;
  ${flexCenter()};
  position: relative;
`;

const CheckMarkWrapper = styled.span`
  color: ${color.active};
  display: none;
  position: absolute;
  right: 0;
  top: 0;
  transform: translate(30%, -30%);
`;

const Input = styled(VisuallyHiddenElement)`
  &:hover + ${Body} {
    border-color: ${color.active};
  }

  &:focus + ${Body} {
    border-color: ${color.active};
  }

  &:checked + ${Body} {
    background: ${color.lightBgGreen};
    border-color: ${color.active};

    ${CheckMarkWrapper} {
      display: block;
    }
  }
`;

const SelectorCard = forwardRef<HTMLInputElement, SelectorCardProps>(
  ({ label, icon, iconSize = 58, subText, className, ...props }, ref) => (
    <label>
      <Input as="input" {...props} type="radio" ref={ref} />

      <Body className={className}>
        <CheckMarkWrapper>
          <Icon name="checkmark" size={iconSize} />
        </CheckMarkWrapper>
        {icon}
        {label}
        {subText}
      </Body>
    </label>
  ),
);

export { SelectorCard };
export type { SelectorCardProps };

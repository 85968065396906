import { AdvertServiceType } from './advert';
import { SelectOption } from './form';

enum UserType {
  Individual = 'INDIVIDUAL',
  Company = 'COMPANY',
}

enum UserRole {
  User = 'USER',
  Admin = 'ADMIN',
}

type User = {
  id: string;
  email: string;
  phone: string;
  role: UserRole;
  type: UserType;
  privacyPolicyAccepted: boolean;
  termsOfUseAccepted: boolean;
  individual: {
    firstName: string;
    lastName: string;
  };
  company: {
    name: string;
    address: string;
    code: string;
    vat: string;
  };
  partner: {
    id: string;
    logoFileId: string;
  } | null;
  passwordLastChangedAt: number | null;
};

type UserFormValues = {
  email: string;
  phone: string;
  type: UserType;
  firstName: string;
  lastName: string;
  companyName: string;
  companyCode: string;
  companyAddress: string;
  companyVat: string;
};

type UserAuthLocationState = {
  email: string;
  phone: string;
};

type ServiceTypeSubscriptionFormValues = {
  serviceTypes: SelectOption[];
};

type ServiceTypeSubscription = {
  serviceTypes: AdvertServiceType[];
};

export { UserType, UserRole };
export type {
  User,
  UserFormValues,
  UserAuthLocationState,
  ServiceTypeSubscription,
  ServiceTypeSubscriptionFormValues,
};

import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { RouteKey } from './routes';
import { IndexScreen } from './screens/IndexScreen';
import { LoadingScreen } from './screens/LoadingScreen';
import { ActiveSubscriptionGuard, RulesConsentGuard } from './guards';

const NotFoundScreen = React.lazy(() => import('./screens/NotFoundScreen'));
const UserSettingsScreen = React.lazy(() => import('./screens/UserSettingsScreen'));
const UserAdvertsScreen = React.lazy(() => import('./screens/UserAdvertsScreen'));
const UserSavedAdvertsScreen = React.lazy(() => import('./screens/UserSavedAdvertsScreen'));
const NewUserAdvertScreen = React.lazy(() => import('./screens/NewUserAdvertScreen'));
const EditUserAdvertScreen = React.lazy(() => import('./screens/EditUserAdvertScreen'));
const PasswordResetScreen = React.lazy(() => import('./screens/PasswordResetScreen'));
const AdvertsScreen = React.lazy(() => import('./screens/AdvertsScreen'));
const PublicAdvertScreen = React.lazy(() => import('./screens/PublicAdvertScreen'));
const PrivacyPolicyScreen = React.lazy(() => import('./screens/PrivacyPolicyScreen'));
const ContactsScreen = React.lazy(() => import('./screens/ContactsScreen'));
const TermsOfUseScreen = React.lazy(() => import('./screens/TermsOfUseScreen'));
const PartnersCatalogueScreen = React.lazy(() => import('./screens/PartnersCatalogueScreen'));

export const MainRouter = () => (
  <Routes>
    <Route path={RouteKey.Index} element={<IndexScreen />} />
    <Route
      path={RouteKey.UserSettings}
      element={
        <React.Suspense fallback={<LoadingScreen />}>
          <RulesConsentGuard>
            <UserSettingsScreen />
          </RulesConsentGuard>
        </React.Suspense>
      }
    />
    <Route
      path={RouteKey.UserAdverts}
      element={
        <React.Suspense fallback={<LoadingScreen />}>
          <RulesConsentGuard>
            <UserAdvertsScreen />
          </RulesConsentGuard>
        </React.Suspense>
      }
    />
    <Route
      path={RouteKey.UserSavedAdverts}
      element={
        <React.Suspense fallback={<LoadingScreen />}>
          <RulesConsentGuard>
            <UserSavedAdvertsScreen />
          </RulesConsentGuard>
        </React.Suspense>
      }
    />
    <Route
      path={RouteKey.NewUserAdvert}
      element={
        <React.Suspense fallback={<LoadingScreen />}>
          <RulesConsentGuard>
            <NewUserAdvertScreen />
          </RulesConsentGuard>
        </React.Suspense>
      }
    />
    <Route
      path={RouteKey.EditUserAdvert}
      element={
        <React.Suspense fallback={<LoadingScreen />}>
          <RulesConsentGuard>
            <EditUserAdvertScreen />
          </RulesConsentGuard>
        </React.Suspense>
      }
    />
    <Route
      path={RouteKey.PasswordReset}
      element={
        <React.Suspense fallback={<LoadingScreen />}>
          <PasswordResetScreen />
        </React.Suspense>
      }
    />
    <Route
      path={RouteKey.PrivacyPolicy}
      element={
        <React.Suspense fallback={<LoadingScreen />}>
          <PrivacyPolicyScreen />
        </React.Suspense>
      }
    />
    <Route
      path={RouteKey.TermsOfUse}
      element={
        <React.Suspense fallback={<LoadingScreen />}>
          <TermsOfUseScreen />
        </React.Suspense>
      }
    />
    <Route
      path={RouteKey.Contacts}
      element={
        <React.Suspense fallback={<LoadingScreen />}>
          <ContactsScreen />
        </React.Suspense>
      }
    />
    <Route
      path={RouteKey.Adverts}
      element={
        <React.Suspense fallback={<LoadingScreen />}>
          <RulesConsentGuard>
            <ActiveSubscriptionGuard>
              <AdvertsScreen />
            </ActiveSubscriptionGuard>
          </RulesConsentGuard>
        </React.Suspense>
      }
    />
    <Route
      path={RouteKey.PublicAdvert}
      element={
        <React.Suspense fallback={<LoadingScreen />}>
          <RulesConsentGuard>
            <ActiveSubscriptionGuard>
              <PublicAdvertScreen />
            </ActiveSubscriptionGuard>
          </RulesConsentGuard>
        </React.Suspense>
      }
    />
    <Route
      path={RouteKey.PartnersCatalogue}
      element={
        <React.Suspense fallback={<LoadingScreen />}>
          <PartnersCatalogueScreen />
        </React.Suspense>
      }
    />
    <Route
      path={RouteKey.NotFound}
      element={
        <React.Suspense fallback={<LoadingScreen />}>
          <NotFoundScreen />
        </React.Suspense>
      }
    />
  </Routes>
);

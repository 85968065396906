import styled from 'styled-components';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { AxiosError } from 'axios';
import { getErrorMessage } from '../../../../common/utils';
import { passwordResetSchema } from '../../../../common/schemas';
import { PasswordResetFormModel, useResetPassword } from '../../../hooks/api/usePasswordReset';
import { size } from '../../../theme/utils';
import { flexCenter } from '../../../theme/mixins';
import { ModalTitle } from '../../ui-lib/modals';
import { FormAlert } from '../../ui-lib/forms/FormAlert';
import { Form, TextInput } from '../../ui-lib/forms';
import { Button } from '../../ui-lib/buttons';
import { Paragraph } from '../../ui-lib/typography';

const TEXT = {
  title: 'Slaptažodžio priminimas',
  email: 'Elektroninis paštas',
  cta: 'Siųsti priminimą',
  successMessageFirstPart: 'Jei el. paštas',
  successMessageSecondPart:
    'yra registruotas, netrukus šiuo el. paštu gausite prisijungimo instrukciją.',
  errorTitle: 'Išsiųsti priminimo nepavyko',
};

const ButtonRow = styled.div`
  margin-top: ${size(2)};
  ${flexCenter()};
`;

const Text = styled(Paragraph)`
  margin: 0 auto;
  max-width: ${size(110)};
`;

const PasswordResetModal = () => {
  const { mutate, isLoading, isError, isSuccess, error } = useResetPassword();
  const { formState, register, handleSubmit, getValues } = useForm<PasswordResetFormModel>({
    mode: 'onSubmit',
    defaultValues: {
      email: '',
    },
    resolver: yupResolver(passwordResetSchema),
  });

  const { errors } = formState;
  const errorMessage = getErrorMessage(error as AxiosError);

  async function onSubmit(formData: PasswordResetFormModel): Promise<void> {
    await mutate(formData);
  }

  return (
    <>
      <ModalTitle variant="medium" title={TEXT.title} />
      {isError && <FormAlert title={TEXT.errorTitle}>{errorMessage}</FormAlert>}
      {isSuccess ? (
        <Text>
          {TEXT.successMessageFirstPart} <strong>{getValues('email')} </strong>
          {TEXT.successMessageSecondPart}
        </Text>
      ) : (
        <Form onSubmit={handleSubmit(onSubmit)}>
          <TextInput
            id="email"
            type="email"
            label={TEXT.email}
            error={errors.email}
            {...register('email', { required: true })}
          />
          <ButtonRow>
            <Button type="submit" disabled={isLoading}>
              {TEXT.cta}
            </Button>
          </ButtonRow>
        </Form>
      )}
    </>
  );
};

export { PasswordResetModal };

/* eslint-disable no-template-curly-in-string */
import * as yup from 'yup';

yup.setLocale({
  mixed: {
    required: 'Privaloma įvesti',
  },
  string: {
    email: 'Įveskite teisingo formato el. paštą',
    min: 'Reikšmė per trumpa (min. ${min} simboliai)',
    max: 'Reikšmė per ilga (max. ${max} simboliai)',
  },
  number: {
    min: 'Reikšmė per maža (min. ${min})',
    max: 'Reikšmė per didelė (max. ${max})',
  },
  array: {
    min: 'Pasirinkite bent vieną',
  },
});

/* eslint-enable no-template-curly-in-string */
export default yup;

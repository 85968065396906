import { createGlobalStyle } from 'styled-components';
import 'sanitize.css';
import 'react-responsive-modal/styles.css';
import { color, size, typography } from '../variables';
import { h1, h2, h3, h4, h5, p, link } from '../typography-styles';
import { rem } from '../utils';

export const GlobalStyles = createGlobalStyle`
  html {
    font-size: ${typography.fontSize};
  }

  html,
  body {
    height: 100%;
    margin: 0;
    padding: 0;
    width: 100%;
  }

  body {
    color: ${color.textPrimary};
    font-family: ${typography.fontFamily};
    font-size: ${typography.fontSize}px;
    font-weight: ${typography.fontWeightRegular};
    line-height: ${typography.defaultLineHeight};
    min-width: ${size.minWidth}px;
  }

  h1 {
    ${h1}
  }

  h2 {
    ${h2}
  }

  h3 {
    ${h3}
  }

  h4 {
    ${h4}
  }

  h5 {
    ${h5}
  }

  a {
    ${link}
  }

  p {
    ${p}
  }

  strong {
    font-weight: ${typography.fontWeightBold};
  }

  input,
  select,
  textarea {
    color: ${color.textPrimary};
    font-family: ${typography.fontFamily};
    font-size: ${rem(14)};
    font-weight: ${typography.fontWeightRegular};
  }

  #app-root {
    background: ${color.bgWhite};
    display: flex;
    flex-direction: column;
    min-height: 100%;
  }

  #map {
    .mapboxgl-popup-content {
      padding: 0;
    }

    .mapboxgl-popup-tip {
      display: none;
    }
  }
`;

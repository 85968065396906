import { ErrorBoundary } from 'react-error-boundary';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { BrowserRouter as Router } from 'react-router-dom';
import useFonts from '../hooks/useFonts';
import { useFocusVisibilityTracker } from '../hooks/useFocusVisibilityTracker';
import { GlobalStyles } from '../theme/global-styles';
import { MainRouter } from '../navigation';
import GlobalModalProvider from '../context/GlobalModalProvider';
import { ErrorPage } from '../components/common/ErrorPage';
import ScrollToTop from './ScrollToTop';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

export const App = () => {
  useFonts();
  useFocusVisibilityTracker();

  return (
    <Router>
      <ScrollToTop>
        <QueryClientProvider client={queryClient}>
          <GlobalModalProvider>
            <GlobalStyles />
            <ErrorBoundary FallbackComponent={ErrorPage}>
              <MainRouter />
            </ErrorBoundary>
          </GlobalModalProvider>
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </ScrollToTop>
    </Router>
  );
};

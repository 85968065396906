import styled from 'styled-components';
import { SubscriptionStatus } from '../../../../common/types';
import { useGetSubscription } from '../../../hooks/api/useSubscription';
import { size } from '../../../theme/utils';
import { useGlobalModal } from '../../../context';
import { Button, ButtonProps } from '../../ui-lib/buttons';
import { Icon } from '../../ui-lib/icons';
import { AddAdvertModal } from '../modals/AddAdvertModal';

type AddAdvertButtonProps = {
  className?: string;
} & ButtonProps;

const TEXT = {
  cta: 'Įkelti skelbimą',
};

const Root = styled(Button)`
  align-items: center;
  display: flex;
  gap: ${size(2)};
`;

const AddAdvertButton = ({ className, ...props }: AddAdvertButtonProps) => {
  const { setModal } = useGlobalModal();
  const { data: subscription } = useGetSubscription();

  const active = subscription?.status === SubscriptionStatus.Active;

  const openAddAdvertModal = () => {
    setModal(<AddAdvertModal />);
  };

  if (!active) {
    return null;
  }

  return (
    <Root onClick={openAddAdvertModal} className={className} {...props}>
      <Icon name="add-circle" size={17} />
      {TEXT.cta}
    </Root>
  );
};

export { AddAdvertButton };

import { useMutation } from 'react-query';
import { userApiClient } from '../../services/apiClient';

const addFile = (
  file: FormData,
): Promise<{ data: { id: string; type: 'webp' | 'png' | 'jpeg' | 'jpg' | 'pdf' } }> =>
  userApiClient.post('/files', file, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

const deleteFile = (advertId: string, fileId: string) =>
  userApiClient.delete(`/adverts/${advertId}/files/${fileId}`);

const useAddFile = () => useMutation(addFile);
const useDeleteFile = () =>
  useMutation(({ advertId, fileId }: { advertId: string; fileId: string }) =>
    deleteFile(advertId, fileId),
  );

export { useDeleteFile, useAddFile };

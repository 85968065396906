import { useForm } from 'react-hook-form';
import { useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import styled from 'styled-components';
import { useQueryClient } from 'react-query';
import { AxiosError } from 'axios';
import { useSearchParams } from 'react-router-dom';
import { User } from '../../../../common/types';
import { getErrorMessage } from '../../../../common/utils';
import { loginSchema } from '../../../../common/schemas';
import { useGlobalModal } from '../../../context';
import { flexCenter } from '../../../theme/mixins';
import { size } from '../../../theme/utils';
import { responsive } from '../../../theme/responsive';
import { useLogin, LoginUserFormModel } from '../../../hooks/api/useLogin';
import { ModalTitle } from '../../ui-lib/modals';
import { FormAlert } from '../../ui-lib/forms/FormAlert';
import { Form, TextInput } from '../../ui-lib/forms';
import { Button, LinkButton } from '../../ui-lib/buttons';
import { Paragraph } from '../../ui-lib/typography';
import { PasswordToggle } from '../buttons';
import { PasswordResetModal } from './PasswordResetModal';
// eslint-disable-next-line import/no-cycle
import { SignUpModal } from './SignUpModal';

const TEXT = {
  title: 'Prisijungimas',
  email: 'Elektroninis paštas',
  password: 'Slaptažodis',
  cta: 'Prisijungti',
  passwordReset: 'Priminti slaptažodį',
  dontHaveAcc: 'Dar neturite paskytos?',
  signUp: 'Registruotis',
  errorTitle: 'Prisijungti nepavyko',
};

const ButtonRow = styled.div`
  ${flexCenter()};
`;

const PasswordResetLink = styled(LinkButton)`
  margin: ${size(2)} 0 ${size(6)} auto;
  text-decoration: underline;
`;

const RegistrationLink = styled(LinkButton)`
  display: inline;
  text-decoration: underline;
`;

const Footer = styled(Paragraph)`
  padding: ${size(13)} ${size(15)} 0;

  @media ${responsive.tabletPortraitUp} {
    padding: ${size(13)} ${size(20)} 0;
  }
`;

const LoginModal = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const queryClient = useQueryClient();
  const { mutate, isLoading, isError, error } = useLogin();
  const { setModal, hideModal } = useGlobalModal();
  const [passwordShown, setPasswordShown] = useState(false);
  const { formState, register, handleSubmit } = useForm<LoginUserFormModel>({
    mode: 'onSubmit',
    defaultValues: {
      email: '',
      password: '',
    },
    resolver: yupResolver(loginSchema),
  });

  const { errors } = formState;
  const errorMessage = getErrorMessage(error as AxiosError);
  const continueParam = searchParams.get('continue');

  async function onSubmit(formData: LoginUserFormModel): Promise<void> {
    await mutate(formData, {
      onSuccess: ({ data }: { data: User }) => {
        if (data.email) {
          queryClient.invalidateQueries();
        }

        if (continueParam) {
          window.location.href = continueParam;
        } else {
          hideModal();
        }
      },
    });
  }

  const onPasswordResetLinkClick = () => {
    searchParams.delete('continue');
    setSearchParams(searchParams);
    setModal(<PasswordResetModal />);
  };

  const onRegisterLinkClick = () => {
    searchParams.delete('continue');
    setSearchParams(searchParams);
    setModal(<SignUpModal />);
  };

  return (
    <>
      <ModalTitle title={TEXT.title} />
      {isError && <FormAlert title={TEXT.errorTitle}>{errorMessage}</FormAlert>}
      <Form onSubmit={handleSubmit(onSubmit)}>
        <TextInput
          id="email"
          type="email"
          label={TEXT.email}
          error={errors.email}
          {...register('email', { required: true })}
        />
        <TextInput
          id="password"
          type={passwordShown ? 'text' : 'password'}
          label={TEXT.password}
          error={errors.password}
          noMargin
          tailingIcon={
            <PasswordToggle passwordShown={passwordShown} setPasswordShown={setPasswordShown} />
          }
          {...register('password', { required: true })}
        />
        <PasswordResetLink type="button" onClick={onPasswordResetLinkClick}>
          {TEXT.passwordReset}
        </PasswordResetLink>
        <ButtonRow>
          <Button type="submit" disabled={isLoading}>
            {TEXT.cta}
          </Button>
        </ButtonRow>
      </Form>
      <Footer>
        {TEXT.dontHaveAcc}{' '}
        <RegistrationLink type="button" onClick={onRegisterLinkClick}>
          {TEXT.signUp}
        </RegistrationLink>
      </Footer>
    </>
  );
};

export { LoginModal };

// THIS FILE IS AUTOGENERATED - DO NOT EDIT THIS FILE
import AddCircle from '../../../../assets/icons/add-circle.svg';
import ArrowLeft from '../../../../assets/icons/arrow-left.svg';
import ArrowRight from '../../../../assets/icons/arrow-right.svg';
import Calendar from '../../../../assets/icons/calendar.svg';
import Checkmark from '../../../../assets/icons/checkmark.svg';
import ChevronDown from '../../../../assets/icons/chevron-down.svg';
import Close from '../../../../assets/icons/close.svg';
import Delete from '../../../../assets/icons/delete.svg';
import Edit from '../../../../assets/icons/edit.svg';
import EyeClosed from '../../../../assets/icons/eye-closed.svg';
import EyeOpen from '../../../../assets/icons/eye-open.svg';
import Hamburger from '../../../../assets/icons/hamburger.svg';
import Heart from '../../../../assets/icons/heart.svg';
import HeartFilled from '../../../../assets/icons/heart-filled.svg';
import Location from '../../../../assets/icons/location.svg';
import Note from '../../../../assets/icons/note.svg';
import Search from '../../../../assets/icons/search.svg';
import SecureShield from '../../../../assets/icons/secure-shield.svg';
import Upload from '../../../../assets/icons/upload.svg';
import User from '../../../../assets/icons/user.svg';
import UserCheck from '../../../../assets/icons/user-check.svg';
import VerifiedAccount from '../../../../assets/icons/verified-account.svg';

const iconMap = {
  'add-circle': AddCircle,
  'arrow-left': ArrowLeft,
  'arrow-right': ArrowRight,
  calendar: Calendar,
  checkmark: Checkmark,
  'chevron-down': ChevronDown,
  close: Close,
  delete: Delete,
  edit: Edit,
  'eye-closed': EyeClosed,
  'eye-open': EyeOpen,
  hamburger: Hamburger,
  heart: Heart,
  'heart-filled': HeartFilled,
  location: Location,
  note: Note,
  search: Search,
  'secure-shield': SecureShield,
  upload: Upload,
  user: User,
  'user-check': UserCheck,
  'verified-account': VerifiedAccount,
};

type IconMapMember = keyof typeof iconMap;

export { iconMap };
export type { IconMapMember };

type MembershipApplication = {
  email: 'string';
  phone: 'string';
};

enum MembershipApplicationStatus {
  Submitted = 'SUBMITTED',
  Approved = 'APPROVED',
}

export { MembershipApplicationStatus };
export type { MembershipApplication };

import { AxiosError } from 'axios';
import styled from 'styled-components';
import { useQueryClient } from 'react-query';
import { getErrorMessage, getPath } from '../../../../common/utils';
import { RouteKey } from '../../../navigation/routes';
import { size } from '../../../theme/utils';
import { useGlobalModal } from '../../../context';
import { useAgreeToRules } from '../../../hooks/api/useAgreeToRules';
import { FormAlert } from '../../ui-lib/forms/FormAlert';
import { Paragraph } from '../../ui-lib/typography';
import { Button } from '../../ui-lib/buttons';
import { NavLink } from '../../ui-lib/links';

const TEXT = {
  errorTitle: 'Sutikti nepavyko',
  descriptionWithLink: () => (
    <>
      Norėdami naudotis svetainės paslaugomis privalote susipažinti ir sutikti su įmonės{' '}
      <NavLink
        to={getPath(RouteKey.PrivacyPolicy)}
        target="_blank"
        style={{ textDecoration: 'underline' }}
      >
        Privatumo politika
      </NavLink>
      {` ir `}
      <NavLink
        to={getPath(RouteKey.TermsOfUse)}
        target="_blank"
        style={{ textDecoration: 'underline' }}
      >
        naudojimosi taisyklėmis
      </NavLink>
      .
    </>
  ),
  agree: 'Sutinku',
};

const ButtonRow = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${size(8)};
`;

const RulesConsentModal = () => {
  const queryClient = useQueryClient();
  const { hideModal } = useGlobalModal();
  const { mutate, isLoading, isError, error } = useAgreeToRules();

  const errorMessage = getErrorMessage(error as AxiosError);

  async function onSubmit(): Promise<void> {
    await mutate(undefined, {
      onSuccess: () => {
        hideModal();
        queryClient.invalidateQueries();
      },
    });
  }

  return (
    <>
      {isError && <FormAlert title={TEXT.errorTitle}>{errorMessage}</FormAlert>}
      <Paragraph>{TEXT.descriptionWithLink()}</Paragraph>
      <ButtonRow>
        <Button onClick={() => onSubmit()} disabled={isLoading}>
          {TEXT.agree}
        </Button>
      </ButtonRow>
    </>
  );
};

export { RulesConsentModal };

import { AxiosError } from 'axios';
import { ErrorMessage } from '../types';
import { ERROR_MESSAGE_LABEL } from '../constants/errorMessage';

const TEXT = {
  default: 'Atsiprašome, įvyko klaida',
};

const getErrorMessage = (error: AxiosError | null) => {
  if (!error) {
    return null;
  }

  // @ts-ignore
  const key = error.response?.data?.key as ErrorMessage;
  if (key) {
    return ERROR_MESSAGE_LABEL[key];
  }

  return TEXT.default;
};

export { getErrorMessage };

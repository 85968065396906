import { rgba } from 'polished';
import ReactModal from 'react-responsive-modal';
import styled from 'styled-components';
import { breakpoints, color, property } from '../../../theme/variables';
import { size as sizeUtil } from '../../../theme/utils';
import { responsive } from '../../../theme/responsive';

interface IModalProps {
  onClose: () => void;
}

type ModalProps = {
  size: 'wide' | 'ultraWide' | 'narrow';
  children: React.ReactNode;
  closable?: boolean;
  lockBody?: boolean;
} & Pick<IModalProps, 'onClose'>;

const MODAL_SIZE = {
  wide: sizeUtil(168),
  narrow: sizeUtil(112),
  ultraWide: breakpoints.desktopMin - 24, // Equals to container size
};

const styles = (modalSize: 'wide' | 'ultraWide' | 'narrow') => ({
  overlay: {
    background: rgba(color.palette.green900, 0.84),
  },
  modalContainer: {
    padding: sizeUtil(4),
  },
  modal: {
    borderRadius: property.borderRadius,
    padding: 0,
    maxWidth: MODAL_SIZE[modalSize],
    borderTop: `4px solid ${color.borderGreen}`,
    margin: 0,
    ...(modalSize !== 'ultraWide' && { width: '100%' }),
  },
});

const ModalContainer = styled.div`
  padding: ${sizeUtil(10)} ${sizeUtil(6)} ${sizeUtil(8)};

  @media ${responsive.tabletLandscapeUp} {
    padding: ${sizeUtil(10)} ${sizeUtil(12)} ${sizeUtil(12)};
  }
`;

const Modal = ({ size, onClose, closable = true, lockBody = true, children }: ModalProps) => {
  const closeProps = closable
    ? {
        onClose,
      }
    : {
        closeOnEsc: false,
        closeOnOverlayClick: false,
        showCloseIcon: false,
        onClose: () => {},
      };

  return (
    <ReactModal blockScroll={lockBody} center open {...closeProps} styles={styles(size)}>
      <ModalContainer>{children}</ModalContainer>
    </ReactModal>
  );
};

export { Modal };
export type { ModalProps, IModalProps };

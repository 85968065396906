import styled from 'styled-components';
import { size } from '../../../theme/utils';
import { breakpoints } from '../../../theme/variables';

type ContainerProps = {
  children: React.ReactNode;
  className?: string;
};

const StyledContainer = styled.div`
  margin: 0 auto;
  max-width: ${breakpoints.desktopMin}px;
  padding: 0 ${size(3)};
  width: 100%;
`;

export const Container = ({ children, className }: ContainerProps) => (
  <StyledContainer className={className}>{children}</StyledContainer>
);

import { useMutation } from 'react-query';
import { publicApiClient } from '../../services/apiClient';

interface SignUpUserFormModel {
  email: string;
  phone: string;
}

const signUp = (data: SignUpUserFormModel) => publicApiClient.put('/membership-applications', data);

export const useSignUp = () => useMutation(signUp);
export type { SignUpUserFormModel };

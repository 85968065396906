import styled, { css } from 'styled-components';
import { useEffect, useState } from 'react';
import { color, property } from '../../../theme/variables';
import { rem, size } from '../../../theme/utils';
import { Heading3, Paragraph } from '../typography';
import { Icon } from '../icons';
import { LinkOrButton } from '../buttons';

type AlertProps = {
  children: React.ReactNode;
  onClose?: () => void;
  title?: string;
  closable?: boolean;
  autoClose?: boolean;
  severity?: 'basic' | 'error' | 'warning' | 'info' | 'success';
  className?: string;
};

const TEXT = {
  close: 'Uždaryti',
};

const CLOSE_TIMEOUT = 3000;

const getSeverityStyles = (severity: string) =>
  ({
    basic: css`
      background-color: ${color.lightBgGrey};
      border-color: ${color.border};
      color: ${color.textPrimary};
    `,
    warning: css`
      background-color: ${color.lightBgBrown};
      border-color: ${color.borderBrown};
      color: ${color.palette.brown700};
    `,
    info: css`
      background-color: ${color.lightBgBlue};
      border-color: ${color.borderBlue};
      color: ${color.palette.blue700};
    `,
    error: css`
      background-color: ${color.lightBgRed};
      border-color: ${color.borderRed};
      color: ${color.invalid};
    `,
    success: css`
      background-color: ${color.lightBgGreen};
      border-color: ${color.borderGreen};
      color: ${color.link};
    `,
  }[severity || 'success']);

const Root = styled.div<{ severity: 'basic' | 'error' | 'warning' | 'info' | 'success' }>`
  border-radius: ${property.borderRadius}px;
  border-style: solid;
  border-width: 1px;
  ${(props) => getSeverityStyles(props.severity)};
  padding: ${size(4)} ${size(7)};
  position: relative;
`;

const Heading = styled(Heading3)`
  color: inherit;
`;

const Text = styled(Paragraph)`
  color: inherit;
  font-size: ${rem(14)};
`;

const CloseButton = styled(LinkOrButton)`
  position: absolute;
  right: ${size(1.5)};
  top: ${size(1.5)};
`;

const Alert = ({
  severity = 'success',
  autoClose = false,
  onClose = () => {},
  closable = true,
  title,
  children,
  className,
}: AlertProps) => {
  const [opened, setOpened] = useState(true);

  useEffect(() => {
    const timeId = setTimeout(() => {
      if (autoClose) {
        setOpened(false);
        onClose();
      }
    }, CLOSE_TIMEOUT);

    return () => {
      clearTimeout(timeId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!opened) {
    return null;
  }

  return (
    <Root severity={severity} role="alert" className={className}>
      {title && <Heading>{title}</Heading>}
      <Text>{children}</Text>
      {closable && (
        <CloseButton onClick={() => setOpened(false)} aria-label={TEXT.close} title={TEXT.close}>
          <Icon name="close" aria-hidden />
        </CloseButton>
      )}
    </Root>
  );
};

export { Alert };
export type { AlertProps };

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { isEmpty } from 'lodash';
import styled from 'styled-components';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { AdvertDirection } from '../../../../common/types';
import { addAdvertInitialSchema } from '../../../../common/schemas';
import { getPath } from '../../../../common/utils';
import { size } from '../../../theme/utils';
import { responsive } from '../../../theme/responsive';
import { flexCenter } from '../../../theme/mixins';
import { RouteKey } from '../../../navigation/routes';
import { ModalTitle } from '../../ui-lib/modals';
import { FormAlert } from '../../ui-lib/forms/FormAlert';
import { Button } from '../../ui-lib/buttons';
import { Form } from '../../ui-lib/forms';
import { SelectorCard } from '../../ui-lib/forms/SelectorCard';
import { Heading2 } from '../../ui-lib/typography';
import SearchTypeIcon from '../../../../assets/illustrations/search-type.svg';
import ProvideTypeIcon from '../../../../assets/illustrations/type-provide.svg';

const TEXT = {
  title: 'Pasirinkite kokio tipo skelbimą norite įkelti',
  searching: 'Ieškau',
  searchingDescription: 'kas suteiktų miško paslaugas',
  providing: 'Teikiu',
  providingDescription: 'profesionalias miško paslaugas',
  cta: 'Pildyti informaciją',
  errorTitle: 'Nepasirinkote skelbimo tipo',
  errorSubtitle:
    'Prieš pradedant pildyti skelbimo informaciją, prašome pasirinkti, kokio tipo skelbimą norite įkelti.',
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${size(10)};

  @media ${responsive.tabletPortraitUp} {
    flex-direction: row;
    justify-content: center;
  }
`;

const Root = styled(Form)`
  max-width: 100%;
`;

const IconWrapper = styled.span`
  width: ${size(29)};
`;

const Heading = styled(Heading2)`
  margin: ${size(3)} 0;
`;

const Selection = styled(SelectorCard)`
  flex: 1;
  padding: ${size(11)} ${size(5)} ${size(7)};
`;

const ButtonRow = styled.div`
  margin-top: ${size(8)};
  ${flexCenter()};
`;

const AddAdvertModal = () => {
  const navigate = useNavigate();

  const { formState, register, handleSubmit } = useForm<{ direction: AdvertDirection }>({
    mode: 'onSubmit',
    defaultValues: {
      direction: undefined,
    },
    resolver: yupResolver(addAdvertInitialSchema),
  });

  const { errors } = formState;

  const onSubmit = ({ direction }: { direction: AdvertDirection }) => {
    navigate({
      pathname: getPath(RouteKey.NewUserAdvert),
      search: createSearchParams({
        direction,
      }).toString(),
    });
  };

  return (
    <>
      <ModalTitle title={TEXT.title} />
      {!isEmpty(errors) && <FormAlert title={TEXT.errorTitle}>{TEXT.errorSubtitle}</FormAlert>}
      <Root onSubmit={handleSubmit(onSubmit)}>
        <Wrapper>
          <Selection
            value={AdvertDirection.Search}
            label={<Heading as="p">{TEXT.searching}</Heading>}
            subText={TEXT.searchingDescription}
            icon={
              <IconWrapper>
                <SearchTypeIcon />
              </IconWrapper>
            }
            {...register('direction', { required: true })}
          />
          <Selection
            value={AdvertDirection.Provide}
            label={<Heading as="p">{TEXT.providing}</Heading>}
            subText={TEXT.providingDescription}
            icon={
              <IconWrapper>
                <ProvideTypeIcon />
              </IconWrapper>
            }
            {...register('direction', { required: true })}
          />
        </Wrapper>
        <ButtonRow>
          <Button type="submit">{TEXT.cta}</Button>
        </ButtonRow>
      </Root>
    </>
  );
};

export { AddAdvertModal };

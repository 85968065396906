import { SelectOption } from '../types';

const ETHNOGRAPHIC_REGIONS: SelectOption[] = [
  {
    value: 'AU',
    label: 'Aukštaitija',
  },
  {
    value: 'DZ',
    label: 'Dzūkija',
  },
  {
    value: 'SU',
    label: 'Suvalkija',
  },
  {
    value: 'ZE',
    label: 'Žemaitija',
  },
  {
    value: 'ML',
    label: 'Mažoji Lietuva',
  },
];

export { ETHNOGRAPHIC_REGIONS };

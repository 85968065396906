import { useEffect } from 'react';

const useFocusVisibilityTracker = () => {
  useEffect(() => {
    const setFocusNotVisible = () => {
      document.body.classList.remove('focus-visible');
    };

    const setFocusVisible = () => {
      document.body.classList.add('focus-visible');
    };

    setFocusVisible();
    document.addEventListener('mousedown', setFocusNotVisible);
    document.addEventListener('keyup', setFocusVisible);

    return () => {
      document.removeEventListener('mousedown', setFocusNotVisible);
      document.removeEventListener('keyup', setFocusVisible);
    };
  }, []);
};

export { useFocusVisibilityTracker };

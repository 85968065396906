import styled from 'styled-components';
import { typography } from '../variables';
import { focus } from '../mixins';
import { rem, size } from '../utils';

const ClearedButton = styled.button`
  appearance: none;
  background: transparent;
  border: 0;
  border-radius: 0;
  box-shadow: none;
  cursor: pointer;
  font-family: ${typography.fontFamily};
  font-size: ${rem(typography.fontSize)};
  font-weight: ${typography.fontWeightRegular};
  line-height: inherit;
  padding: 0;
  text-align: left;

  &:focus {
    ${focus}
  }
`;

const ClearedInput = styled.input`
  appearance: none;
  background: none;
  border: 0;
  border-radius: 0;
  color: inherit;
  font-family: inherit;
  font-size: inherit;
  line-height: 1;
  outline: none;
  padding: 0;
`;

const VisuallyHiddenElement = styled.span`
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
`;

const ClearedUl = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`;

const ClearedOl = styled.ol`
  margin: 0;
  padding-left: ${size(4)};
`;

const ClearedDl = styled.dl`
  margin: 0;
`;

const ClearedTextArea = styled.textarea`
  background-color: transparent;
  border: none;
  outline: none;
  resize: none;
`;

export {
  ClearedButton,
  ClearedInput,
  VisuallyHiddenElement,
  ClearedUl,
  ClearedDl,
  ClearedTextArea,
  ClearedOl,
};

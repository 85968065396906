import styled from 'styled-components';
import { MouseEvent, useState } from 'react';
import { darken, lighten } from 'polished';
import { Advert } from '../../../../common/types';
import { color, property } from '../../../theme/variables';
import { ClearedButton } from '../../../theme/resets';
import { flexCenter } from '../../../theme/mixins';
import { size } from '../../../theme/utils';
import { useForgetAdvert, useSaveAdvert } from '../../../hooks/api/useSaveAdvert';
import { Icon } from '../../ui-lib/icons';

type SaveAdvertButtonProps = {
  advert: Advert;
  onSuccess?: () => void;
  activeColor: string;
  className?: string;
};

const TEXT = {
  saveLabel: 'Įsiminti skelbimą',
  deleteLabel: 'Pamiršti įsimintą skelbimą',
};

const Root = styled(ClearedButton)<{ active: boolean; activeColor: string }>`
  background-color: ${({ active, activeColor }) => (active ? activeColor : color.bgWhite)};
  border-radius: ${property.borderRadius}px;
  color: ${color.palette.black};
  display: inline-flex;
  ${flexCenter()};
  padding: ${size(2)};
  transition: background-color ${property.transition};

  &:hover {
    /* stylelint-disable-next-line */
    background-color: ${({ active, activeColor }) =>
      active ? darken(0.2, activeColor) : lighten(0.1, activeColor)};
    color: ${color.palette.white};
  }
`;

const SaveAdvertButton = ({ advert, onSuccess, activeColor, className }: SaveAdvertButtonProps) => {
  const [saved, setSaved] = useState(advert.saved);
  const { mutate: forgetAdvert } = useForgetAdvert(advert.id);
  const { mutate: saveAdvert } = useSaveAdvert(advert.id);

  const iconName = saved ? 'heart-filled' : 'heart';
  const text = saved ? TEXT.deleteLabel : TEXT.saveLabel;

  async function onSubmit(e: MouseEvent<HTMLButtonElement>): Promise<void> {
    e.preventDefault();
    e.stopPropagation();
    const action = saved ? forgetAdvert : saveAdvert;

    await action(undefined, {
      onSuccess: () => {
        setSaved(!saved);
        onSuccess?.();
      },
    });
  }

  return (
    <Root
      className={className}
      active={saved}
      activeColor={activeColor}
      onClick={(e) => onSubmit(e)}
      aria-label={text}
    >
      <Icon name={iconName} />
    </Root>
  );
};

export { SaveAdvertButton };

enum DeforestationType {
  SelectiveLogging = 'SELECTIVE_LOGGING', // Atrankinis kirtimas
  SelectiveSanitaryLogging = 'SELECTIVE_SANITARY_LOGGING', // Atrankinis sanitarinis kirtimas
  RandomLogging = 'RANDOM_LOGGING', // Atvėjinis kirtimas
  LastInstanceOfRandomLogging = 'LAST_INSTANCE_OF_RANDOM_LOGGING', // Atvėjinių kirtimų paskutinis atvėjis
  BiodiversityMaintenanceLogging = 'BIODIVERSITY_MAINTENANCE_LOGGING', // Biologinės įvairovės palaikymo kirtimas
  CurrentFall = 'CURRENT_FALL', // Einamasis kritimas
  LandscapingLogging = 'LANDSCAPING_LOGGING', // Kraštovaizdžio formavimo kirtimas
  ClearLogging = 'CLEAR_LOGGING', // Plynas kirtimas
  ClearSanitaryLogging = 'CLEAR_SANITARY_LOGGING', // Plynas sanitarinis kirtimas
  ReconstructiveLogging = 'RECONSTRUCTIVE_LOGGING', // Rekonstrukcinis kirtimas
  Thinning = 'THINNING', // Retinimas
}

export { DeforestationType };

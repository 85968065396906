import { useQuery } from 'react-query';
import { User } from '../../../common/types';
import { userApiClient } from '../../services/apiClient';

const getUserData = async (): Promise<User> => {
  const response = await userApiClient.get('/');
  const { data } = response;

  return data;
};

const useGetUserData = () =>
  useQuery<User>('userData', getUserData, { retry: false, refetchOnMount: false });

export { useGetUserData };

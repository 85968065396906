import { RouteKey as AppRouteKey } from '../../app/navigation/routes';
import { RouteKey as AdminRouteKey } from '../../adminApp/navigation/routes';

const getPath = (
  routeKey: AppRouteKey | AdminRouteKey,
  params?: Record<string, string | number>,
) => {
  let paramKey;
  let paramValue;

  if (params) {
    const [key, value] = Object.entries(params)[0];
    paramKey = key;
    paramValue = value;
  }

  return routeKey.replace(`:${paramKey}`, paramValue ? paramValue.toString() : '');
};

export { getPath };

export enum RouteKey {
  Index = '/',
  UserSettings = '/user/settings',
  UserAdverts = '/user/adverts/uploaded',
  UserSavedAdverts = '/user/adverts/saved',
  NewUserAdvert = '/user/adverts/new',
  EditUserAdvert = '/user/adverts/:id',
  Adverts = '/advert-search/:direction',
  PublicAdvert = '/advert/:id',
  PasswordReset = '/password/reset',
  PrivacyPolicy = '/privacy-policy',
  TermsOfUse = '/terms-of-use',
  Contacts = '/contacts',
  PartnersCatalogue = '/partners',
  NotFound = '*',
}

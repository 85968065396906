import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Modal } from '../components/ui-lib/modals';

interface IContextProps {
  setModal: (
    modal: React.ReactNode,
    size?: 'wide' | 'ultraWide' | 'narrow',
    closable?: boolean,
    lockBody?: boolean,
  ) => void;
  hideModal: () => void;
}

const initialState: IContextProps = {
  setModal: () => {},
  hideModal: () => {},
};

const GlobalModalContext = createContext(initialState);

type SetModalProps = {
  modal: React.ReactNode;
  size?: 'wide' | 'ultraWide' | 'narrow';
  closable?: boolean;
  lockBody?: boolean;
};

type Props = {
  children: React.ReactNode;
};

type LocationType = {
  pathname: string;
  state?: { ignoreCloseModal: boolean };
};

const GlobalModalProvider = ({ children }: Props) => {
  const [{ modal, size, closable, lockBody }, setModal] = useState<SetModalProps>({
    modal: undefined,
  });
  const location = useLocation();
  const { pathname, state } = location as LocationType;

  const hideModal = useCallback(() => {
    setModal({ modal: undefined });
  }, [setModal]);

  useEffect(() => {
    if (!state || (state && !state.ignoreCloseModal)) {
      hideModal();
    }
  }, [hideModal, pathname, state]);

  const value = useMemo(
    () => ({
      setModal: (
        modalComponent: React.ReactNode,
        modalSize?: 'wide' | 'ultraWide' | 'narrow',
        modalClosable?: boolean,
        modalLockBody?: boolean,
      ) =>
        setModal({
          modal: modalComponent,
          size: modalSize,
          closable: modalClosable,
          lockBody: modalLockBody,
        }),
      hideModal,
    }),
    [hideModal],
  );

  return (
    <GlobalModalContext.Provider value={value}>
      {children}
      {modal && (
        <Modal onClose={hideModal} closable={closable} lockBody={lockBody} size={size || 'wide'}>
          {modal}
        </Modal>
      )}
    </GlobalModalContext.Provider>
  );
};

export default GlobalModalProvider;

export const useGlobalModal = () => useContext(GlobalModalContext);

import { useMutation } from 'react-query';
import { baseApiClient } from '../../services/apiClient';

interface LoginUserFormModel {
  email: string;
  password: string;
}

const login = (data: LoginUserFormModel) => {
  const formData = new FormData();

  formData.append('username', data.email);
  formData.append('password', data.password);

  return baseApiClient.post('/login', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const useLogin = () => useMutation(login);
export type { LoginUserFormModel };

enum TreeSpecies {
  Oak = 'OAK', // Ąžuolas
  WhiteAlder = 'WHITE_ALDER', // Baltalksnis
  Birch = 'BIRCH', // Beržas
  GoatWillow = 'GOAT_WILLOW', // Blindė
  Aspen = 'ASPEN', // Drebulė
  Spruce = 'SPRUCE', // Eglė
  Willow = 'WILLOW', // Gluosnis
  Elm = 'ELM', // Guoba
  BlackAlder = 'BLACK_ALDER', // Juodalksnis
  Maple = 'MAPLE', // Klevas
  Linden = 'LINDEN', // Liepa
  Larch = 'LARCH', // Maumedis
  Pine = 'PINE', // Pušis
  Hornbeam = 'HORNBEAM', // Skroblas
  CottonWood = 'COTTONWOOD', // Tuopa
  Ash = 'ASH', // Uosis
  OtherHardwoods = 'OTHER_HARDWOODS', // Kiti kietieji lapuočiai
  OtherSoftwoods = 'OTHER_SOFTWOODS', // kiti minkštieji lapuočiai
  OtherConifers = 'OTHER_CONIFERS', // Kiti spygliuočiai
  Other = 'OTHER', // Kita
}

export { TreeSpecies };

import { PropsWithChildren } from 'react';
import { Navigate } from 'react-router-dom';
import { getPath, isAdvertViewable } from '../../../common/utils';
import { useGetUserData } from '../../hooks/api/useUserData';
import { useGetSubscription } from '../../hooks/api/useSubscription';
import { RouteKey } from '../routes';

const ActiveSubscriptionGuard = ({ children }: PropsWithChildren<{}>) => {
  const { data: user, isFetched: userFetched } = useGetUserData();
  const { data: subscription, isFetched: subscriptionFetched } = useGetSubscription(user);
  const advertsViewable = isAdvertViewable(subscription);

  if (!(userFetched && subscriptionFetched)) {
    return null;
  }

  if (!advertsViewable) {
    return <Navigate to={getPath(RouteKey.NotFound)} />;
  }

  return <>{children}</>;
};

export { ActiveSubscriptionGuard };

import styled from 'styled-components';
import { getPath } from '../../../../common/utils';
import { color, property } from '../../../theme/variables';
import { rem, size } from '../../../theme/utils';
import { link } from '../../../theme/typography-styles';
import { responsive } from '../../../theme/responsive';
import { RouteKey } from '../../../navigation/routes';
import { Paragraph } from '../../ui-lib/typography';
import { NavLink } from '../../ui-lib/links';
import { Container } from '../layout';

const TEXT = {
  copyright: '© Miško paslaugos. Visos teisės saugomos',
  contacts: 'Kontaktai',
  privacyPolicy: 'Privatumo politika',
  termsOfUse: 'Naudojimosi taisyklės',
};

const Root = styled.footer`
  background-color: ${color.bgWhite};
  border-top: 1px solid ${color.lightBgGrey};
  box-shadow: ${property.boxShadowSmall};
  padding: ${size(3)} 0;
`;

const StyledContainer = styled(Container)`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: ${size(2)};

  @media ${responsive.tabletPortraitUp} {
    flex-direction: row;
    justify-content: space-between;
  }
`;

const Copyright = styled(Paragraph)`
  font-size: ${rem(14)};
`;

const Link = styled(NavLink)`
  ${link};
`;

const Nav = styled.nav`
  align-items: flex-start;
  display: flex;
  gap: ${size(4)};
  text-align: center;
`;

const Footer = () => (
  <Root>
    <StyledContainer>
      <Copyright>{TEXT.copyright}</Copyright>
      <Nav>
        <Link to={getPath(RouteKey.Contacts)}>{TEXT.contacts}</Link>
        <Link to={getPath(RouteKey.PrivacyPolicy)}>{TEXT.privacyPolicy}</Link>
        <Link to={getPath(RouteKey.TermsOfUse)}>{TEXT.termsOfUse}</Link>
      </Nav>
    </StyledContainer>
  </Root>
);

export { Footer };

import { SelectOption } from './form';
import { TreeSpecies } from './trees';
import { DeforestationType } from './deforestationType';
import { DeforestationServiceType } from './deforestationServiceType';
import { RoundTimberAssortment } from './roundTimberAssortment';
import { ForestRoadsServiceType } from './forestRoadsServiceType';
import { QuarryProduction } from './quarryProduction';
import { TransportServiceType } from './transportServiceType';

enum AdvertDirection {
  Search = 'SEARCH',
  Provide = 'PROVIDE',
}

enum AdvertServiceType {
  Plowing = 'PLOWING',
  ForestPlanting = 'FOREST_PLANTING',
  Deforestation = 'DEFORESTATION',
  RepellentLubrication = 'REPELLENT_LUBRICATION',
  SelfGreeneryMowing = 'SELF_GREENERY_MOWING',
  SaplingGrowing = 'SAPLING_GROWING',
  ForestManagementProjects = 'FOREST_MANAGEMENT_PROJECTS',
  WoodLotDissection = 'WOOD_LOT_DISSECTION',
  WoodTrade = 'WOOD_TRADE',
  VirginForestTrade = 'VIRGIN_FOREST_TRADE',
  ForestWithLandTrade = 'FOREST_WITH_LAND_TRADE',
  Biofuels = 'BIOFUELS',
  ForestRoads = 'FOREST_ROADS',
  Transport = 'TRANSPORT',
}

type ForestPlantingSeedlingSpecies =
  | TreeSpecies.Other
  | TreeSpecies.Spruce
  | TreeSpecies.Pine
  | TreeSpecies.Birch;

type Advert = {
  id: string;
  visible: boolean;
  saved: boolean;
  serviceType: AdvertServiceType;
  direction: AdvertDirection;
  areaHectares: number;
  description: string;
  advertiser: {
    userId: string;
    email: string;
    phone: string;
  };
  cadastralNumber?: {
    location: string;
    block: string;
    plot: string;
  };
  forestPlanting?: {
    seedlingSpecies: ForestPlantingSeedlingSpecies[];
  };
  deforestation?: {
    types: DeforestationType[];
    serviceType: DeforestationServiceType;
    liquidVolumeSquareMeters: number;
    speciesToBeFelled: TreeSpecies[];
  };
  virginForestTrade?: {
    deforestationTypes: DeforestationType[];
    liquidVolumeSquareMeters: number;
    speciesToBeFelled: TreeSpecies[];
  };
  woodTrade?: {
    roundTimberAssortments: RoundTimberAssortment[];
    volumeCubicMeters: number;
    treeSpecies: TreeSpecies[];
  };
  biofuels?: {
    preliminaryVolumeCubicMeters: number;
  };
  forestRoads?: {
    distanceMeters: number;
    types: ForestRoadsServiceType[];
    quarryProduction: QuarryProduction;
  };
  transport?: {
    volumeSquareMeters: number;
    types: TransportServiceType[];
  };
  pin?: {
    latitude: number;
    longitude: number;
  };
  ethnographicRegions?: string[];
  endsAt: number;
  files: {
    fileId: string;
    fileName: string;
    type: string;
  }[];
};

type AdvertLocationState = {
  state: {
    success?: boolean;
    error?: boolean;
  };
};

type AdvertFilter = {
  direction?: AdvertDirection;
  nearestMe?: string;
  ethnographicRegions?: string;
  serviceType?: string;
  bounds?: string;
};

type AdvertFilterPayload = {
  serviceType?: AdvertServiceType;
  direction?: AdvertDirection;
  longitude1?: number;
  latitude1?: number;
  longitude2?: number;
  latitude2?: number;
  ethnographicRegions?: string[];
};

type AdvertFormValues = {
  direction: AdvertDirection;
  serviceType: AdvertServiceType;
  description: string;
  areaHectares: number;
  visible: boolean;
  latitude?: number;
  longitude?: number;
  cadastralNumberLocation?: string;
  cadastralNumberBlock?: string;
  cadastralNumberPlot?: string;
  ethnographicRegions?: SelectOption[];
  fileIds: string[];
  seedlingSpecies?: SelectOption[];
  felledSpecies?: SelectOption[];
  timberSpecies?: SelectOption[];
  deforestationTypes?: SelectOption[];
  deforestationServiceType?: SelectOption;
  roundTimberAssortments?: SelectOption[];
  distanceMeters?: number;
  forestRoadsServiceType?: SelectOption[];
  quarryProduction?: SelectOption;
  liquidVolume?: number;
  volume?: number;
  transportServiceType?: SelectOption[];
  volumeSquareMeters?: number;
};

type AdvertPayloadValues = {
  visible: boolean;
  serviceType: AdvertServiceType;
  direction: AdvertDirection;
  areaHectares?: number;
  description: string;
  cadastralNumber?: {
    location?: string;
    block?: string;
    plot?: string;
  };
  pin?: {
    latitude: number;
    longitude: number;
  };
  ethnographicRegions?: string[];
  forestPlanting?: {
    seedlingSpecies?: ForestPlantingSeedlingSpecies[];
  };
  deforestation?: {
    types?: DeforestationType[];
    serviceType: DeforestationServiceType;
    liquidVolumeSquareMeters?: number;
    speciesToBeFelled?: TreeSpecies[];
  };
  virginForestTrade?: {
    deforestationTypes?: DeforestationType[];
    liquidVolumeSquareMeters?: number;
    speciesToBeFelled?: TreeSpecies[];
  };
  woodTrade?: {
    roundTimberAssortments?: RoundTimberAssortment[];
    volumeCubicMeters?: number;
    treeSpecies?: TreeSpecies[];
  };
  biofuels?: {
    preliminaryVolumeCubicMeters?: number;
  };
  forestRoads?: {
    distanceMeters?: number;
    types?: ForestRoadsServiceType[];
    quarryProduction?: QuarryProduction;
  };
  transport?: {
    volumeSquareMeters?: number;
    types?: TransportServiceType[];
  };
  fileIds?: string[];
};

export { AdvertServiceType, AdvertDirection };
export type {
  Advert,
  AdvertLocationState,
  AdvertFilter,
  AdvertFormValues,
  AdvertPayloadValues,
  AdvertFilterPayload,
  ForestPlantingSeedlingSpecies,
};

import { Navigate } from 'react-router-dom';
import { PropsWithChildren } from 'react';
import { getPath } from '../../../common/utils';
import { useGetUserData } from '../../hooks/api/useUserData';
import { RouteKey } from '../routes';

const RulesConsentGuard = ({ children }: PropsWithChildren<{}>) => {
  const { data: user, isFetched } = useGetUserData();

  if (!isFetched) {
    return null;
  }

  if (user && (!user.privacyPolicyAccepted || !user.termsOfUseAccepted)) {
    return <Navigate to={getPath(RouteKey.Index)} state={{ ignoreCloseModal: true }} />;
  }

  return <>{children}</>;
};

export { RulesConsentGuard };

import styled from 'styled-components';
import { NavLink as RootNavLink, NavLinkProps } from 'react-router-dom';

import { focus } from '../../../theme/mixins';

const Root = styled(RootNavLink)`
  &:focus {
    ${focus}
  }
`;

const NavLink = (props: NavLinkProps) => <Root {...props} />;

export { NavLink };

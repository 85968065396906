import styled from 'styled-components';
import { size } from '../../../theme/utils';
import { flexCenter } from '../../../theme/mixins';
import { Heading1 } from '../../ui-lib/typography';
import { Button } from '../../ui-lib/buttons';
import { Container } from '../layout';
// @ts-ignore
// eslint-disable-next-line import/no-unresolved
import errorImg from '../../../../assets/error.svg?url';

const TEXT = {
  title: 'Įvyko klaida',
  reload: 'Perkraukite puslapį',
};

const Image = styled.img`
  margin-left: ${size(10)};
  max-width: ${size(125)};
`;

const EmptyPage = styled.div`
  flex: 1;
  ${flexCenter()}
  text-align: center;
`;

const Heading = styled(Heading1)`
  margin: ${size(5)} 0;
`;

const ErrorPage = () => (
  <EmptyPage>
    <Container>
      <Image src={errorImg} alt={TEXT.title} />
      <Heading>{TEXT.title}</Heading>
      <Button onClick={() => window.location.reload()}>{TEXT.reload}</Button>
    </Container>
  </EmptyPage>
);

export { ErrorPage };

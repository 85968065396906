import { css } from 'styled-components';
import { color, typography } from '../variables';
import { rem } from '../utils';

const headingBase = css<TypographyProps>`
  color: ${({ secondary }) => (secondary ? color.textSecondary : color.textPrimary)};
  font-style: ${({ italic }) => (italic ? 'italic' : 'normal')};
  font-weight: ${typography.fontWeightBold};
  line-height: ${typography.headingLineHeight};
  margin: 0;
  text-transform: none;
`;

const h1 = css`
  font-size: ${rem(28)};
  ${headingBase};
`;

const h2 = css`
  font-size: ${rem(24)};
  ${headingBase};
`;

const h3 = css`
  font-size: ${rem(18)};
  ${headingBase};
`;

const h4 = css`
  font-size: ${rem(15)};
  ${headingBase};
`;

const h5 = css`
  font-size: ${rem(14)};
  ${headingBase};
`;

const p = css<TypographyProps>`
  color: ${({ secondary }) => (secondary ? color.textSecondary : color.textPrimary)};
  font-size: ${rem(16)};
  font-style: ${({ italic }) => (italic ? 'italic' : 'normal')};
  font-weight: ${({ bold }) => (bold ? typography.fontWeightBold : typography.fontWeightRegular)};
  margin: 0;
`;

const subHeader = css`
  color: ${color.textSecondary};
  font-size: ${rem(16)};
  font-weight: ${typography.fontWeightRegular};
  line-height: 1.375;
`;

const placeholder = css`
  color: ${color.placeholder};
  font-size: ${rem(14)};
  font-weight: ${typography.fontWeightRegular};
  margin: 0;
`;

const link = css`
  color: ${color.link};
  font-size: ${rem(14)};
  font-weight: ${typography.fontWeightRegular};
  margin: 0;
  text-decoration: none;

  &:hover,
  &:focus {
    color: ${color.linkHover};
  }

  &:focus {
    outline: none;
  }
`;

const errorText = css`
  color: ${color.invalid};
  font-size: ${rem(12)};
  font-weight: ${typography.fontWeightRegular};
`;

const label = css`
  color: ${color.textPrimary};
  font-size: ${rem(13)};
  font-weight: ${typography.fontWeightBold};
`;

export type TypographyProps = {
  bold?: boolean;
  italic?: boolean;
  secondary?: boolean;
};

export { h1, h2, h3, h4, h5, p, placeholder, errorText, link, subHeader, label };
